import { IonButton, IonCol, IonIcon, IonRow } from '@ionic/react';
import React from 'react';

import { FulfillmentMethod } from '@biteinc/enums';

import './fulfillment-method-picker.scss';

import { LocalizationHelper } from '@biteinc/core-react';

import { BaseComponent } from '../base-component';

interface FulfillmenthMethodPickerProps {
  selectedMethod: FulfillmentMethod;
  fulfillmentMethods: FulfillmentMethod[];
  methodPicked: (method: FulfillmentMethod) => void;
}

const fulfillmentMethodImageByMethod: Record<FulfillmentMethod, string> = {
  [FulfillmentMethod.CATERING_CURBSIDE]: '/assets/fulfillment-method-curbside-outline-2.svg',
  [FulfillmentMethod.FLASH_CURBSIDE]: '/assets/fulfillment-method-curbside-outline-2.svg',
  [FulfillmentMethod.WEB_CURBSIDE]: '/assets/fulfillment-method-curbside-outline-2.svg',

  [FulfillmentMethod.CATERING_DELIVERY]: '/assets/fulfillment-method-delivery-truck-outline-2.svg',
  [FulfillmentMethod.FLASH_DELIVERY]: '/assets/fulfillment-method-delivery-truck-outline-2.svg',
  [FulfillmentMethod.WEB_DELIVERY]: '/assets/fulfillment-method-delivery-truck-outline-2.svg',

  [FulfillmentMethod.CATERING_DINE_IN]: '/assets/fulfillment-method-dine-in.svg',
  [FulfillmentMethod.FLASH_DINE_IN]: '/assets/fulfillment-method-dine-in.svg',
  [FulfillmentMethod.KIOSK_DINE_IN]: '/assets/fulfillment-method-pickup-outpost.svg',
  [FulfillmentMethod.LINEBUSTER_DINE_IN]: '/assets/fulfillment-method-pickup-outpost.svg',
  [FulfillmentMethod.WEB_DINE_IN]: '/assets/fulfillment-method-dine-in.svg',

  [FulfillmentMethod.CATERING_OUTPOST]: '/assets/fulfillment-method-pickup-outpost.svg',
  [FulfillmentMethod.FLASH_OUTPOST]: '/assets/fulfillment-method-pickup-outpost.svg',
  [FulfillmentMethod.KIOSK_OUTPOST]: '/assets/fulfillment-method-pickup-outpost.svg',
  [FulfillmentMethod.WEB_OUTPOST]: '/assets/fulfillment-method-pickup-outpost.svg',

  [FulfillmentMethod.CATERING_TO_GO]: '/assets/fulfillment-method-to-go-bag.svg',
  [FulfillmentMethod.DRIVE_THRU_TO_GO]: '/assets/fulfillment-method-to-go-bag.svg',
  [FulfillmentMethod.FLASH_TO_GO]: '/assets/fulfillment-method-to-go-bag.svg',
  [FulfillmentMethod.KIOSK_TO_GO]: '/assets/fulfillment-method-to-go-bag.svg',
  [FulfillmentMethod.LINEBUSTER_TO_GO]: '/assets/fulfillment-method-to-go-bag.svg',
  [FulfillmentMethod.WEB_TO_GO]: '/assets/fulfillment-method-to-go-bag.svg',
};

export default class FulfillmentMethodPicker extends BaseComponent<
  FulfillmenthMethodPickerProps,
  {}
> {
  getFulFillmentMethodButtons(): React.ReactNode[] {
    const buttons: React.ReactNode[] = [];
    this.props.fulfillmentMethods.forEach((method: FulfillmentMethod) => {
      const isSelected = method === this.props.selectedMethod;
      buttons.push(
        React.createElement(
          IonCol,
          {
            size: '4',
            className: 'fulfillment-method-button-container',
          },
          React.createElement(
            IonButton,
            {
              className: `fulfillment-method-button method-${method} ${
                isSelected ? 'activated' : ''
              }`,
              color: 'primary',
              fill: 'outline',
              onClick: () => {
                this.props.methodPicked(method);
              },
            },
            React.createElement(
              IonRow,
              null,
              React.createElement(
                IonCol,
                { size: '12' },
                React.createElement(IonIcon, {
                  className: 'fulfillment-method-icon',
                  icon: fulfillmentMethodImageByMethod[method],
                }),
              ),
              React.createElement(
                IonCol,
                { size: '12' },
                this.localize(LocalizationHelper.localizeEnum.FulfillmentMethod(method)),
              ),
            ),
          ),
        ),
      );
    });
    return buttons;
  }

  render(): JSX.Element {
    return React.createElement(IonRow, null, this.getFulFillmentMethodButtons());
  }
}
