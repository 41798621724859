import { IonButton, IonCol, IonItem, IonLabel, IonList, IonListHeader } from '@ionic/react';
import _ from 'lodash';
import React from 'react';

import { Strings } from '@biteinc/common';
import type { CustomerFavorites, OrderedItem } from '@biteinc/core-react';

import type { BaseState } from '../../../../components';
import { BaseComponent, ConfirmAlert } from '../../../../components';

interface FavoritesListProps {
  favorites: CustomerFavorites[];
  onDelete: { (id: string, orderedItemId: string, idx: number): void };
}

interface FavoritesListState extends BaseState {
  showConfirm: boolean;
  selectedFavorite?: {
    locationFavorites: CustomerFavorites;
    orderedItem: OrderedItem;
    idx: number;
  } | null;
}

class FavoritesList extends BaseComponent<FavoritesListProps, FavoritesListState> {
  constructor(props: FavoritesListProps) {
    super(props);

    this.state = {
      showConfirm: false,
      selectedFavorite: null,
    };
  }

  getMods(orderedItem: OrderedItem): React.ReactNode[] {
    const nodes: React.ReactNode[] = [];
    if (orderedItem.priceOption.name) {
      nodes.push(
        React.createElement('span', {
          dangerouslySetInnerHTML: { __html: `<p>${orderedItem.priceOption.name}</p>` },
        }),
      );
    }
    return nodes;
  }

  getFavoritesForLocation(locationFavorites: CustomerFavorites): React.ReactNode[] {
    const nodes: React.ReactNode[] = [];
    if (!_.size(locationFavorites.orderedItems)) {
      nodes.push(
        React.createElement(
          'p',
          { className: 'ion-text-center' },
          this.localize(Strings.FAVORITES_NONE_YET),
        ),
      );
    } else {
      locationFavorites.orderedItems.forEach((orderedItem, idx) => {
        nodes.push(
          React.createElement(
            IonItem,
            null,
            React.createElement(
              IonLabel,
              { className: 'favorite-item' },
              React.createElement('span', {
                dangerouslySetInnerHTML: { __html: `<p>${orderedItem.name}</p>` },
              }),
              this.getMods(orderedItem),
            ),
            React.createElement(
              IonButton,
              {
                slot: 'end',
                size: 'small',
                color: 'danger',
                onClick: () => {
                  this.setState({
                    showConfirm: true,
                    selectedFavorite: {
                      locationFavorites,
                      orderedItem,
                      idx,
                    },
                  });
                },
              },
              this.localize(Strings.REMOVE),
            ),
          ),
        );
      });
    }
    return nodes;
  }

  getLocationNameFromFavorite(favorite: CustomerFavorites, idx: number): string {
    if (!favorite.location) {
      return `Location ${idx + 1}`;
    }
    return favorite.location.name;
  }

  getFavoritesByLocation(): React.ReactNode[] {
    if (!this.props.favorites.length) {
      return [React.createElement('p', { className: 'ion-text-center' }, 'No favorites yet.')];
    }

    const nodes: React.ReactNode[] = [];
    this.props.favorites.forEach((favorite, idx) => {
      nodes.push(
        React.createElement(
          IonListHeader,
          { className: 'ion-margin-top' },
          this.getLocationNameFromFavorite(favorite, idx),
        ),
        this.getFavoritesForLocation(favorite),
      );
    });
    return nodes;
  }

  render(): React.ReactNode {
    return React.createElement(
      IonCol,
      null,
      React.createElement(IonList, { lines: 'full' }, this.getFavoritesByLocation()),
      React.createElement(ConfirmAlert, {
        show: this.state.showConfirm,
        message: this.localize(Strings.FAVORITES_REMOVE_CONFIRMATION),
        onConfirm: () => {
          this.setState({ showConfirm: false });
          if (this.state.selectedFavorite) {
            this.props.onDelete(
              this.state.selectedFavorite.locationFavorites._id,
              this.state.selectedFavorite.orderedItem._id,
              this.state.selectedFavorite.idx,
            );
          }
        },
        onCancel: () => {
          this.setState({ showConfirm: false });
        },
      }),
    );
  }
}

export default FavoritesList;
