/// /node_modules/@types/googlemaps/index.d.ts" />
import { IonButton } from '@ionic/react';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import { AppearanceHelper } from '@biteinc/core-react';

import { GoogleMapsClient } from '../../clients';
import { LocationHelper } from '../../helpers';
import type { LocationSearchResult, Org } from '../../types';

import './locations-map.scss';

interface LocationsMapProps {
  locations: LocationSearchResult[];
  org: Org;
  apiKey: string;
  updateMarkers: boolean;
  selectedLocation?: (location: LocationSearchResult) => void;
}

class LocationsMap extends Component<LocationsMapProps, {}> {
  private readonly mapContainerId = 'map-container';

  private mapsClient?: GoogleMapsClient;

  private declare map: google.maps.Map;

  private mapRef = React.createRef();

  private locationMarkers: google.maps.Marker[] = [];

  private async getMapsClient(): Promise<GoogleMapsClient> {
    if (!this.mapsClient) {
      this.mapsClient = await GoogleMapsClient.init(this.props.apiKey);
    }
    return this.mapsClient;
  }

  private async createMap(): Promise<void> {
    this.map = (await this.getMapsClient()).createMap(this.mapRef, {
      center: {
        lat: 43.642567,
        lng: -79.387054,
      },
    });
  }

  private getLocationInfoWindow(location: LocationSearchResult): React.ReactElement {
    return React.createElement(
      'div',
      null,
      React.createElement('h6', { className: 'location-name' }, location.name),
      LocationHelper.addressAsMultipleLines(location.fullAddress),
      React.createElement(
        IonButton,
        {
          size: 'small',
          color: 'primary',
          className: 'select-location-button',
          onClick: () => {
            if (this.props.selectedLocation) {
              this.props.selectedLocation(location);
            }
          },
        },
        'Select Location',
      ),
    );
  }

  private showInfoWindow(
    mapsClient: GoogleMapsClient,
    infoWindow: google.maps.InfoWindow,
    marker: google.maps.Marker,
    location: LocationSearchResult,
  ): void {
    infoWindow.open(this.map, marker);
    mapsClient.addListener(infoWindow, 'domready', () => {
      const infoWindowContent = this.getLocationInfoWindow(location);
      ReactDOM.render(
        React.Children.only(infoWindowContent),
        document.getElementById(`location-info-window-${location._id}`),
      );
    });
  }

  private async addMarkers(): Promise<void> {
    if (this.locationMarkers.length) {
      this.locationMarkers.forEach((marker) => {
        marker.setMap(null);
      });
      this.locationMarkers = [];
    }
    if (this.props.locations.length) {
      const mapsClient = await this.getMapsClient();
      this.locationMarkers = this.props.locations.map((location, idx) => {
        const { coordinates } = location;
        const infoWindow = mapsClient.createLocationInfoWindowContainer(location);
        const marker = mapsClient.createMarker({
          map: this.map,
          coordinates,
          setAsCenter: !idx,
          iconUrl: AppearanceHelper.getOrgImage(this.props.org, 'customMapMarker'),
        });
        marker.addListener('click', () => {
          this.showInfoWindow(mapsClient, infoWindow, marker, location);
        });
        // Open the first location in the list by default
        if (!idx) {
          this.showInfoWindow(mapsClient, infoWindow, marker, location);
        }
        return marker;
      });
    }
  }

  async componentDidMount(): Promise<void> {
    if (!this.map) {
      await this.createMap();
    }
    await this.addMarkers();
  }

  async componentDidUpdate(): Promise<void> {
    if (this.map && this.props.updateMarkers) {
      await this.addMarkers();
    }
  }

  render(): React.ReactNode {
    return React.createElement('div', {
      id: this.mapContainerId,
      ref: this.mapRef,
      style: {
        width: '100%',
        height: '100%',
      },
    });
  }
}

const mapStateToProps = (
  state: any,
): {
  apiKey: string;
  org: Org;
} => {
  return {
    apiKey: state.googleApiKey,
    org: state.org,
  };
};

export default connect(mapStateToProps)(LocationsMap);
