import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router';

import { ErrorCode } from '@biteinc/common';
import type { Location, OrderedItem } from '@biteinc/core-react';
import { AppearanceHelper } from '@biteinc/core-react';
import { CheckinType } from '@biteinc/enums';

import { MaitredClient } from '../../clients';
import { TextInput } from '../../forms';

import './checkin-page.scss';

interface CheckinPageProps extends RouteComponentProps<{ orderId: string }> {
  checkinType?: CheckinType;
  checkinInstructions?: string;
  urlSlug: string;
  host: string;
  appearance: any;
  settings: any;
}

interface CheckinPageState {
  code: string;
  sent: boolean;
  showCodeEntry: boolean;
  order?: any;
  error?: string;
  loading: boolean;
}

/**
 * @todo
 * Move `sent` to its own view
 */
class CheckinPage extends Component<CheckinPageProps, CheckinPageState> {
  constructor(props: CheckinPageProps) {
    super(props);
    this.state = {
      code: '',
      showCodeEntry: true,
      sent: false,
      order: null,
      loading: true,
    };
  }

  private doCheckin(): void {
    this.setState({ loading: true });
    const { orderId } = this.props.match.params;
    const payload = {
      ...(this.state.code && { code: this.state.code }),
    };

    const resource = `/api/v2/orders/${orderId}/checkin`;
    void MaitredClient.put(resource, payload).then(async (result) => {
      if (!result.success) {
        if (result.code === ErrorCode.OrderAlreadySent) {
          this.setState({
            sent: true,
            loading: false,
          });
          return;
        }
        this.setState({
          error: result.message,
          loading: false,
        });
        return;
      }

      const response = await MaitredClient.waitForJob(result.queuePath);
      if (response.jobState !== 'completed') {
        this.setState({
          error: result.message,
          loading: false,
        });
        return;
      }

      this.setState({
        sent: true,
        loading: false,
      });
    });
  }

  componentDidMount(): void {
    const { orderId } = this.props.match.params;
    void MaitredClient.get(`/api/v2/orders/${orderId}`).then(({ order, message }) => {
      if (order) {
        this.setState({ order });
        // check if order is already sent
        // do we have to wait for state to be set here?
        if (this.state.order?.checkedInAt && this.state.order?.wasSentToPos) {
          this.setState({ sent: true });
        }
      }
      if (message) {
        this.setState({ error: message });
      }
      this.setState({ loading: false });
    });
    if (this.props.checkinType === CheckinType.Button) {
      this.setState({ showCodeEntry: false });
    }
  }

  handleChange(code: string): void {
    if (_.size(code) < 5) {
      this.setState({ code });
    }
  }

  onCheckin(): void {
    if (this.props.checkinType === CheckinType.Code && _.size(this.state.code) < 4) {
      this.setState({ error: 'Please enter a valid code ' });
    } else {
      this.doCheckin();
    }
  }

  getWelcomeMessage(): string {
    if (this.props.checkinInstructions) {
      return this.props.checkinInstructions;
    }
    if (this.props.checkinType === CheckinType.Code) {
      return 'Enter the 4-digit code on the screen to collect your order.';
    }
    return 'Click Check-In below to collect your order.';
  }

  getBaseView(): React.ReactNode {
    const instructions = this.getWelcomeMessage();
    return React.createElement(
      'div',
      null,
      React.createElement(
        'h1',
        {
          className: 'instructions',
        },
        instructions,
      ),
      React.createElement(TextInput, {
        hidden: !this.state.showCodeEntry,
        value: this.state.code,
        placeholder: 'Enter Code',
        change: this.handleChange.bind(this),
      }),
      React.createElement(
        'button',
        {
          className: 'submit',
          type: 'submit',
          onClick: this.onCheckin.bind(this),
        },
        'CHECK IN',
      ),
    );
  }

  getOrderSize(): number {
    return this.state.order.orderedItems.reduce((count: number, orderedItem: OrderedItem) => {
      return count + orderedItem.priceOption.quantity;
    }, 0);
  }

  getOrderSummary(): React.ReactNode {
    return React.createElement(
      'div',
      { className: 'order-info' },
      React.createElement('p', null, 'Your order number:'),
      React.createElement('p', { className: 'order-number' }, this.state.order.clientNumber),
      React.createElement('p', { className: 'order-count' }, `(${this.getOrderSize()} items)`),
    );
  }

  getErrorContainer(message: string | undefined): React.ReactNode {
    if (message) {
      return React.createElement('p', { className: 'error' }, message);
    }
    return '';
  }

  /**
   * @todo
   * Break sent state into sub-views
   */
  render(): React.ReactNode {
    let view;
    let orderSummary;
    if (this.state.loading) {
      orderSummary = React.createElement('h3', null, 'Loading...');
    } else if (this.state.sent) {
      view = React.createElement(
        'div',
        null,
        React.createElement(
          'h1',
          { className: 'instructions success' },
          'Checkin successful! Your order will be ready shortly, and your name will be called.',
        ),
        React.createElement(
          'a',
          { href: `${this.props.host}/${this.props.urlSlug}` },
          'Place another order',
        ),
      );
      orderSummary = this.getOrderSummary();
    } else if (this.state.order) {
      view = this.getBaseView();
      orderSummary = this.getOrderSummary();
    }
    const img = React.createElement('img', {
      className: 'logo',
      alt: 'logo',
      src: AppearanceHelper.getTitleImage(this.props.settings),
    });
    const error = this.getErrorContainer(this.state.error);
    return (
      <div className="checkin-page">
        {img}
        {view}
        {error}
        {orderSummary}
      </div>
    );
  }
}

const mapStateToProps = (state: any): Omit<CheckinPageProps, 'history' | 'location' | 'match'> => {
  const location = state.location as Location;
  return {
    checkinType: location.checkinType,
    checkinInstructions: location.checkinInstructions,
    urlSlug: location.urlSlug,
    appearance: state.menu.appearance,
    settings: state.menu.settings,
    host: state.flashResolvedHost,
  };
};

export default connect(mapStateToProps)(CheckinPage);
