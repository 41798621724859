import { IonButton, IonItem, IonLabel, IonList, IonText } from '@ionic/react';
import React, { Component } from 'react';

import { StringHelper } from '@biteinc/core-react';

import { LocationHelper } from '../../helpers';
import type { LocationSearchResult } from '../../types';

import './location-list.scss';

interface LocationListProps {
  locations: LocationSearchResult[];
  onLocationSelect: (location: LocationSearchResult) => void;
}

class LocationList extends Component<LocationListProps, any> {
  private readonly errorMessage =
    'We were unable to find any participating locations in your area for online ordering. Please enter another address and include city and state for the most accurate results.';

  private phoneNumberLink(phoneNumber: string): React.ReactNode {
    if (!phoneNumber) {
      return undefined;
    }
    return React.createElement(
      'a',
      { className: 'phone-number', href: `tel:${phoneNumber}` },
      React.createElement(
        IonText,
        { color: 'primary' },
        StringHelper.toFormattedPhoneNumber(phoneNumber),
      ),
    );
  }

  private getLocationItems(): React.ReactNode[] {
    const nodes: React.ReactNode[] = [];
    if (this.props.locations.length) {
      this.props.locations.forEach((location) => {
        nodes.push(
          React.createElement(
            IonItem,
            { className: 'location-info' },
            React.createElement(
              'h3',
              { className: 'location-distance' },
              `${location.distanceFromGuest.toFixed(1)} mi`,
            ),
            React.createElement(
              IonLabel,
              null,
              React.createElement('h3', { className: 'location-name' }, location.name),
              LocationHelper.addressAsMultipleLines(location.fullAddress),
              this.phoneNumberLink(location.phoneNumber),
            ),
            React.createElement(
              IonButton,
              {
                slot: 'end',
                color: 'primary',
                size: 'default',
                onClick: () => {
                  this.props.onLocationSelect(location);
                },
              },
              'Start Order',
            ),
          ),
        );
      });
    } else {
      nodes.push(
        React.createElement(
          IonItem,
          { className: 'location-info' },
          React.createElement(
            IonLabel,
            { className: 'ion-text-wrap' },
            React.createElement('h3', { className: 'ion-text-center' }, this.errorMessage),
          ),
        ),
      );
    }
    return nodes;
  }

  render(): JSX.Element {
    return React.createElement(IonList, { lines: 'none' }, this.getLocationItems());
  }
}

export default LocationList;
