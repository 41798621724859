import { IonLabel, IonPage, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import { Route } from 'react-router-dom';

import { Header } from '@biteinc/core-react';

import { headerConnector } from '../../connectors';
import OrdersPage from '../../pages/orders-page/orders-page';
import PasswordResetPage from '../../pages/password-reset-page/password-reset-page';
import PaymentsPage from '../../pages/payments-page/payments-page';
import ProfilePage from '../../pages/profile-page/profile-page';

export interface TabsLayoutProps extends RouteComponentProps {
  canStorePaymentMethods: boolean;
}

class TabsLayout extends Component<TabsLayoutProps, {}> {
  getRoutes(url: string): React.ReactNode[] {
    const nodes: React.ReactNode[] = [
      React.createElement(Route, {
        path: `${url}/profile`,
        exact: true,
        component: ProfilePage,
      }),
      React.createElement(Route, {
        path: `${url}/profile/reset-password`,
        exact: true,
        component: PasswordResetPage,
      }),
    ];
    if (this.props.canStorePaymentMethods) {
      nodes.push(
        React.createElement(Route, {
          path: `${url}/payment`,
          exact: true,
          component: PaymentsPage,
        }),
      );
    }
    nodes.push(
      React.createElement(Route, {
        path: `${url}/orders`,
        exact: true,
        component: OrdersPage,
      }),
    );
    return nodes;
  }

  getTabButtons(url: string): React.ReactNode[] {
    const nodes: React.ReactNode[] = [
      React.createElement(
        IonTabButton,
        { tab: 'profile', href: `${url}/profile` },
        React.createElement(IonLabel, null, 'Profile'),
      ),
    ];
    if (this.props.canStorePaymentMethods) {
      nodes.push(
        React.createElement(
          IonTabButton,
          { tab: 'payment', href: `${url}/payment` },
          React.createElement(IonLabel, null, 'Payment'),
        ),
      );
    }
    nodes.push(
      React.createElement(
        IonTabButton,
        { tab: 'orders', href: `${url}/orders` },
        React.createElement(IonLabel, null, 'Orders'),
      ),
    );
    return nodes;
  }

  getTabs(): React.ReactNode {
    const { url } = this.props.match;
    return React.createElement(
      IonTabs,
      null,
      React.createElement(
        IonRouterOutlet,
        null,
        React.createElement(
          IonPage,
          null,
          React.createElement(headerConnector(Header)),
          this.getRoutes(url),
        ),
      ),
      React.createElement(IonTabBar, { slot: 'bottom' }, this.getTabButtons(url)),
    );
  }

  render(): React.ReactNode {
    return this.getTabs();
  }
}

const mapStateToProps = (
  state: any,
): {
  canStorePaymentMethods: boolean;
} => {
  return {
    canStorePaymentMethods: state.canStorePaymentMethods,
  };
};

export default connect(mapStateToProps)(TabsLayout);
