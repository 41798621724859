import { IonButton, IonCol, IonContent, IonPage, IonRow } from '@ionic/react';
import _ from 'lodash';
import qs from 'qs';
import React from 'react';
import { connect } from 'react-redux';

import { Strings } from '@biteinc/common';
import { Header, SignupSchema } from '@biteinc/core-react';
import { ApiResource, ApiVersion } from '@biteinc/enums';

import { MaitredClient, Storage } from '../../clients';
import { Logo } from '../../components';
import { headerConnector } from '../../connectors';
import { BaseFormComponent } from '../../forms';
import ResponsiveContent from '../../layout/responsive-content/responsive-content';

class SignupPage extends BaseFormComponent<any, any> {
  resource: ApiResource = ApiResource.Customer;

  version: ApiVersion = ApiVersion.V2;

  path = 'signup';

  schema = SignupSchema;

  constructor(props: any) {
    super(props);
    this.state = {
      showToast: false,
      toastMessage: null,
      toastColor: null,
      success: false,
      data: {
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        phoneNumber: null,
        orderId: null,
      },
    };
  }

  async submit(): Promise<void> {
    const result = await super.submit();
    if (result?.success) {
      this.setState({
        success: true,
        showToast: true,
        toastColor: 'success',
        toastMessage: result.message,
      });
    } else {
      this.setState({
        showToast: true,
        toastColor: 'danger',
        toastMessage: result.message,
      });
    }
  }

  getShortCode(): string | undefined | null {
    return Storage.getItem(`${MaitredClient.getScope()}:urlSlug`);
  }

  componentDidMount(): void {
    const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const stateFromQuery = {
      ...(_.isString(query.name) &&
        query.name && {
          firstName: query.name.split(' ')[0].trim(),
          lastName: query.name.split(' ').slice(1).join(' ').trim(),
        }),
      ...(_.isString(query.email) &&
        query.email && {
          email: query.email,
        }),
      ...(_.isString(query.phoneNumber) &&
        query.phoneNumber.match(/^[0-9]{10}$/) && {
          phoneNumber: query.phoneNumber,
        }),
      ...(_.isString(query.orderId) &&
        query.orderId.match(/^[0-9A-F]{24}$/i) && {
          orderId: query.orderId,
        }),
    };
    if (_.size(stateFromQuery)) {
      this.setState({ data: stateFromQuery });
    }
  }

  render(): React.ReactNode {
    return React.createElement(
      IonPage,
      null,
      React.createElement(headerConnector(Header)),
      React.createElement(
        IonContent,
        { className: 'ion-padding' },
        React.createElement(
          ResponsiveContent,
          null,
          React.createElement(Logo, null),
          React.createElement(
            IonRow,
            null,
            React.createElement(
              IonCol,
              null,
              React.createElement('h1', { className: 'ion-text-center' }, 'Sign Up'),
            ),
          ),
          React.createElement(IonRow, null, React.createElement(IonCol, null, this.generateForm())),
          React.createElement(
            IonRow,
            { className: 'ion-text-center' },
            React.createElement(
              IonCol,
              null,
              React.createElement(
                'p',
                null,
                this.localize(Strings.CREATE_ACCOUNT_TERMS_LINE),
                React.createElement(
                  'p',
                  null,
                  React.createElement(
                    IonButton,
                    {
                      fill: 'clear',
                      color: 'dark',
                      href: `${this.props.host}/${this.getShortCode()}/terms-of-service`,
                      target: '_blank',
                    },
                    this.localize(Strings.TERMS_OF_SERVICE),
                  ),
                ),
              ),
            ),
          ),
          React.createElement(
            IonRow,
            null,
            React.createElement(
              IonCol,
              null,
              React.createElement(
                IonButton,
                { expand: 'block', onClick: this.submit.bind(this) },
                this.localize(Strings.CREATE_ACCOUNT),
              ),
            ),
          ),
        ),
        this.showToast(this.state.showToast, this.state.toastMessage, this.state.toastColor),
      ),
    );
  }
}

const mapStateToProps = (
  state: any,
): {
  host: string;
} => {
  return {
    host: state.flashResolvedHost,
  };
};

export default connect(mapStateToProps)(SignupPage);
