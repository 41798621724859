import Cookies from 'js-cookie';

export function setItem(key: string, value: string): void {
  if (!localStorage) {
    Cookies.set(key, value);
  } else {
    localStorage.setItem(key, value);
  }
}

export function setJsonItem(key: string, value: any): void {
  setItem(key, JSON.stringify(value));
}

export function getItem(key: string): string | undefined | null {
  if (!localStorage) {
    return Cookies.get(key);
  }
  return localStorage.getItem(key);
}

export function getJsonItem(key: string): string | undefined | null {
  const value = getItem(key);
  if (value) {
    try {
      return JSON.parse(value);
    } catch {
      return undefined;
    }
  }
  return undefined;
}

export function removeItem(key: string): void {
  if (!localStorage) {
    Cookies.remove(key);
  }
  localStorage.removeItem(key);
}
