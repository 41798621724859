import type { AnyAction } from 'redux';

import type { Customer } from '@biteinc/core-react';

export enum CustomerAction {
  SET_CUSTOMER = 'set-customer',
}

export function setCustomer(customer: Customer | null): AnyAction {
  return {
    type: CustomerAction.SET_CUSTOMER,
    customer,
  };
}

const initialState: Customer | null = null;

/**
 * REDUCER
 * - Do not mutate object directly
 */
export function CustomerReducer(state: Customer | null = initialState, action: AnyAction): any {
  switch (action.type) {
    case CustomerAction.SET_CUSTOMER:
      return {
        ...action.customer,
      };
  }
  return state;
}
