import { IonButton, IonCol, IonContent, IonRow } from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';

import { ApiResource, ApiVersion, CustomerAuthProvider } from '@biteinc/enums';

import { MaitredClient, RequestMethod } from '../../clients';
import { BaseFormComponent } from '../../forms';
import type { FormProps } from '../../forms/base-form-component';
import ResponsiveContent from '../../layout/responsive-content/responsive-content';
import { profileEditSchema } from '../../schemas';

interface ProfileProps extends FormProps {
  authProvider?: CustomerAuthProvider;
}

class ProfilePage extends BaseFormComponent<ProfileProps, any> {
  resource: ApiResource = ApiResource.Customer;

  version: ApiVersion = ApiVersion.V2;

  path = '';

  schema = profileEditSchema;

  method: RequestMethod = RequestMethod.PUT;

  constructor(props: any) {
    super(props);
    if (props.authProvider !== CustomerAuthProvider.Bite) {
      delete this.schema.passwordReset;
    }
    this.state = {
      showToast: false,
      toastMessage: null,
      toastColor: null,
      data: {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
      },
    };
  }

  async componentDidMount(): Promise<void> {
    const url = MaitredClient.generateUrl(this.version, this.resource, this.path);
    const result = await MaitredClient.get(url, true);
    if (result?.success) {
      this.setState({
        data: {
          ...result.customer,
        },
      });
    }
  }

  async submit(): Promise<void> {
    const result = await super.submit();
    if (result.success) {
      this.setState({
        showToast: true,
        toastColor: 'success',
        toastMessage: 'Profile successfully updated',
      });
    } else {
      this.setState({
        showToast: true,
        toastColor: 'danger',
        toastMessage: result.message,
      });
    }
  }

  render(): React.ReactNode {
    return React.createElement(
      IonContent,
      { className: 'ion-padding' },
      React.createElement(
        ResponsiveContent,
        null,
        React.createElement(
          IonRow,
          null,
          React.createElement(
            IonCol,
            { size: '10', offset: '1' },
            React.createElement('h1', { className: 'ion-text-center' }, 'Edit Profile'),
          ),
        ),
        React.createElement(IonRow, null, React.createElement(IonCol, null, this.generateForm())),
        React.createElement(
          IonRow,
          null,
          React.createElement(
            IonCol,
            null,
            React.createElement(
              IonButton,
              { expand: 'block', onClick: this.submit.bind(this) },
              'Save',
            ),
          ),
        ),
      ),
      this.showToast(this.state.showToast, this.state.toastMessage, this.state.toastColor),
    );
  }
}

const mapStateToProps = (
  state: any,
): {
  authProvider: CustomerAuthProvider;
} => {
  return {
    authProvider: state.org.customerAuthProvider,
  };
};

export default connect(mapStateToProps)(ProfilePage);
