import { IonButton, IonCol, IonContent, IonPage, IonRow } from '@ionic/react';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import type { Schema } from '@biteinc/core-react';
import { Header, LoginSchema } from '@biteinc/core-react';
import type { CustomerAuthProvider } from '@biteinc/enums';
import { ApiResource, ApiVersion } from '@biteinc/enums';

import { MaitredClient, Storage } from '../../clients';
import { Logo } from '../../components';
import { headerConnector } from '../../connectors';
import { BaseFormComponent } from '../../forms';
import type { FormProps } from '../../forms/base-form-component';
import ResponsiveContent from '../../layout/responsive-content/responsive-content';

import './login-page.scss';

import { Strings } from '@biteinc/common';

interface LoginProps extends FormProps {
  authProvider?: CustomerAuthProvider;
  signupUrl?: string;
}

class LoginPage extends BaseFormComponent<LoginProps, any> {
  resource: ApiResource = ApiResource.Customer;

  version: ApiVersion = ApiVersion.V2;

  path = 'login';

  schema: Schema = LoginSchema;

  constructor(props: any) {
    super(props);
    this.schema = LoginPage.schemaFromAuthProvider(props.authProvider);
    this.state = {
      showToast: false,
      toastMessage: null,
      toastColor: null,
      data: {
        email: null,
        password: null,
      },
    };
  }

  private getForgotPasswordButton(): React.ReactNode {
    return React.createElement(
      IonCol,
      { size: '12' },
      React.createElement(
        IonButton,
        {
          className: 'forgot-password',
          fill: 'clear',
          color: 'dark',
          onClick: this.resetRequest.bind(this),
        },
        this.localize(Strings.FORGOT_PASSWORD),
      ),
    );
  }

  /**
   * We only care about the fields for specific auth providers - default to NONE
   */
  static schemaFromAuthProvider(authProvider: CustomerAuthProvider): Schema {
    const schema: Schema = {};
    const properties = _.keys(LoginSchema);
    properties.forEach((property: string) => {
      const fieldProperties = LoginSchema[property];
      if (_.includes(fieldProperties.authProviders, authProvider)) {
        schema[property] = LoginSchema[property];
      }
    });
    return schema;
  }

  resetRequest(): void {
    this.props.history.push(`/${MaitredClient.getScope()}/auth/reset-password-request`);
  }

  newAccount(): void {
    if (this.props.signupUrl) {
      window.location.href = this.props.signupUrl;
      return;
    }
    this.props.history.push(`/${MaitredClient.getScope()}/auth/signup`);
  }

  async submitForm(ev: any): Promise<void> {
    ev?.preventDefault();
    const result = await this.submit();
    if (result?.success) {
      Storage.setItem(MaitredClient.getTokenKey(), result.token);
      this.props.history.push(`/${MaitredClient.getScope()}/account/profile`);
    } else {
      this.setState({
        showToast: true,
        toastMessage: result.message,
        toastColor: 'danger',
      });
    }
  }

  render(): React.ReactNode {
    return React.createElement(
      IonPage,
      null,
      React.createElement(headerConnector(Header)),
      React.createElement(
        IonContent,
        { className: 'ion-padding' },
        React.createElement(
          ResponsiveContent,
          null,
          React.createElement(Logo, null),
          React.createElement(
            IonRow,
            null,
            React.createElement(
              IonCol,
              null,
              React.createElement(
                'h1',
                { className: 'ion-text-center' },
                this.localize(Strings.LOGIN),
              ),
            ),
          ),
          React.createElement(
            'form',
            { onSubmit: this.submitForm.bind(this) },
            React.createElement(
              IonRow,
              null,
              React.createElement(IonCol, null, this.generateForm()),
            ),
            React.createElement(
              IonRow,
              null,
              React.createElement(
                IonCol,
                { size: '6', offset: '3' },
                React.createElement(
                  IonButton,
                  {
                    expand: 'block',
                    onClick: this.submitForm.bind(this),
                  },
                  this.localize(Strings.CUSTOMER_ACCOUNT_BUTTON_LOG_IN),
                ),
                // Ionic React Webcomponents hack
                React.createElement('input', { type: 'submit', className: 'hidden-input' }),
              ),
              this.getForgotPasswordButton(),
              React.createElement(
                IonCol,
                { size: '6', offset: '3' },
                React.createElement(
                  IonButton,
                  {
                    expand: 'block',
                    color: 'light',
                    onClick: this.newAccount.bind(this),
                  },
                  this.localize(Strings.CREATE_A_NEW_ACCOUNT),
                ),
              ),
            ),
          ),
        ),
        this.showToast(this.state.showToast, this.state.toastMessage, this.state.toastColor),
      ),
    );
  }
}

const mapStateToProps = (
  state: any,
): {
  authProvider: CustomerAuthProvider;
  signupUrl: string;
} => {
  return {
    authProvider: state.org.customerAuthProvider,
    signupUrl: state.org.signupUrl,
  };
};

export default connect(mapStateToProps)(LoginPage);
