import { IonCol, IonRow } from '@ionic/react';
import React, { Component } from 'react';

export default class ResponsiveContent extends Component<{ children: React.ReactNode }> {
  render(): React.ReactNode {
    return React.createElement(
      IonRow,
      { className: 'ion-justify-content-center' },
      React.createElement(
        IonCol,
        {
          sizeSm: '12',
          sizeMd: '8',
          sizeLg: '6',
          sizeXl: '4',
        },
        this.props.children,
      ),
    );
  }
}
