import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import { AppearanceHelper } from '@biteinc/core-react';

import { LoadingSpinner } from './components';
import { ProtectedRoute } from './guards';
import TabsLayout from './layout/tabs/tabs-layout';
import {
  CheckinCodeDisplayPage,
  CheckinPage,
  LocationSearchPage,
  LoginPage,
  PasswordResetPage,
  PasswordResetRequestPage,
  SignupPage,
  VerifyAccountPage,
} from './pages';
import { Store } from './store';

import './app.scss';

export default class App extends Component {
  componentWillMount(): void {
    const { menu, org } = Store.getState();
    if (menu?.appearance) {
      const customCssUrl = AppearanceHelper.getCustomCssUrl(menu.appearance);
      if (customCssUrl) {
        AppearanceHelper.addLinkToDom(customCssUrl);
      }
      /**
       * @deprecated
       * Stop using once all locations use new build version
       */
      const customCss = AppearanceHelper.getCustomCss(menu.appearance);
      if (!customCssUrl && customCss) {
        AppearanceHelper.addCssToDom(customCss);
      }
      const customFonts = AppearanceHelper.getCustomFonts(menu.appearance);
      AppearanceHelper.addFontsToDom(customFonts);
    }
    if (org?.brandColor) {
      AppearanceHelper.addBrandColorToDom(org.brandColor);
    }
    if (org?.cssUrl) {
      AppearanceHelper.addLinkToDom(org.cssUrl);
    }
    /**
     * @deprecated
     * Remove once css urls are used
     */
    if (!org.cssUrl && org?.css) {
      AppearanceHelper.addCssToDom(org.css);
    }
  }

  /**
   * @todo
   * - Dynamic routing based on module as this is too hard coded
   */
  render(): JSX.Element {
    return (
      <IonApp className="app">
        <LoadingSpinner />
        <IonReactRouter basename="/">
          <IonRouterOutlet>
            <Route
              path="/"
              exact
              component={LocationSearchPage}
            />
            <ProtectedRoute
              path="/:orgScope/account"
              component={TabsLayout}
            />
            <Route
              path="/:orgScope/auth/login"
              exact
              component={LoginPage}
            />
            <Route
              path="/:orgScope/auth/signup"
              exact
              component={SignupPage}
            />
            <Route
              path="/:orgScope/auth/reset-password"
              exact
              render={(props: any) => (
                <PasswordResetPage
                  {...props}
                  showLogin={true}
                />
              )}
            />
            <Route
              path="/:orgScope/auth/reset-password-request"
              exact
              component={PasswordResetRequestPage}
            />
            <Route
              path="/:orgScope/auth/verify-account"
              exact
              component={VerifyAccountPage}
            />
            <Route
              path="/:urlSlug/checkin"
              exact
              component={CheckinCodeDisplayPage}
            />
            <Route
              path="/:urlSlug/checkin/:checkinToken"
              exact
              component={CheckinPage}
            />
            <Route
              path="/:urlSlug/orders/:orderId/checkin"
              exact
              component={CheckinPage}
            />
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    );
  }
}
