import { IonButton, IonCol, IonContent, IonRow } from '@ionic/react';
import _ from 'lodash';
import qs from 'qs';
import React from 'react';
import { connect } from 'react-redux';

import { Strings } from '@biteinc/common';
import { PasswordResetSchema } from '@biteinc/core-react';
import { ApiResource, ApiVersion, CustomerAuthProvider } from '@biteinc/enums';

import { MaitredClient, Storage } from '../../clients';
import { BaseFormComponent } from '../../forms';
import type { FormProps, FormState } from '../../forms/base-form-component';
import ResponsiveContent from '../../layout/responsive-content/responsive-content';
import { AuthService } from '../../services';

interface ResetPasswordData {
  resetToken?: string | null;
  currentPassword?: string | null;
  newPassword?: string | null;
  newPasswordRepeat?: string | null;
}

interface PasswordResetProps extends FormProps {
  showLogin?: boolean;
  authProvider?: CustomerAuthProvider;
}

interface PasswordResetState extends FormState<ResetPasswordData> {
  showSignInButton?: boolean;
  toastMessage?: string | null | undefined;
  toastColor?: string | null | undefined;
}

class PasswordResetPage extends BaseFormComponent<PasswordResetProps, PasswordResetState> {
  resource: ApiResource = ApiResource.Customer;

  version: ApiVersion = ApiVersion.V2;

  path = 'change-password';

  schema = PasswordResetSchema;

  constructor(props: any) {
    super(props);
    if (props.authProvider !== CustomerAuthProvider.Bite) {
      this.path = `${this.path}/${props.authProvider}`;
    }
    this.state = {
      showSignInButton: false,
      showToast: false,
      toastMessage: null,
      toastColor: null,
      data: {
        resetToken: null,
        currentPassword: null,
        newPassword: null,
        newPasswordRepeat: null,
      },
    };
  }

  async goToSignIn(): Promise<void> {
    const { success } = await AuthService.showLogin();
    if (success) {
      this.props.history.push(`/${MaitredClient.getScope()}/account/profile`);
    }
  }

  showSignIn(): React.ReactNode {
    if (this.props.showLogin && this.state.showSignInButton) {
      return React.createElement(
        IonRow,
        null,
        React.createElement(
          IonCol,
          { size: '6', offset: '3' },
          React.createElement(
            'p',
            { className: 'ion-text-center' },
            this.localize(Strings.CHANGE_PASSWORD_SUCCESS),
          ),
        ),
        React.createElement(
          IonCol,
          { size: '6', offset: '3' },
          React.createElement(
            IonButton,
            {
              expand: 'block',
              onClick: this.goToSignIn.bind(this),
            },
            this.localize(Strings.CUSTOMER_ACCOUNT_BUTTON_LOG_IN),
          ),
        ),
      );
    }
    return null;
  }

  baseContent(): React.ReactNode[] | undefined {
    if (!this.state.showSignInButton) {
      return [
        React.createElement(
          IonRow,
          { className: 'password-reset-container' },
          React.createElement(
            IonCol,
            { size: '10', offset: '1' },
            React.createElement(
              'h1',
              { className: 'ion-text-center' },
              this.localize(Strings.CHANGE_PASSWORD),
            ),
          ),
        ),
        React.createElement(
          IonRow,
          null,
          React.createElement(IonCol, { size: '10', offset: '1' }, this.generateForm()),
        ),
        React.createElement(
          IonRow,
          null,
          React.createElement(
            IonCol,
            { size: '10', offset: '1' },
            React.createElement(
              IonButton,
              {
                expand: 'block',
                className: 'password-reset-button',
                onClick: this.submit.bind(this),
              },
              this.localize(Strings.CHANGE_PASSWORD),
            ),
          ),
        ),
      ];
    }
  }

  async submit(): Promise<void> {
    const result = await super.submit();
    if (result?.success) {
      // Don't set the token - got to be a better way here??
      if (!this.props.showLogin) {
        Storage.setItem('token', result.token);
      }
      this.setState({
        ...(this.props.showLogin && { showSignInButton: true }),
        showToast: true,
        data: {},
        toastColor: 'success',
        toastMessage: 'Password successfully updated',
      });
    } else {
      this.setState({
        showToast: true,
        toastColor: 'danger',
        toastMessage: result.message,
      });
    }
  }

  componentDidMount(): void {
    const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    if (query.rt) {
      this.setState({
        data: {
          ..._.pickBy(this.state.data, _.identity),
          resetToken: query.rt as string,
        },
      });
    }
  }

  render(): React.ReactNode {
    return React.createElement(
      IonContent,
      { className: 'ion-padding' },
      React.createElement(
        ResponsiveContent,
        null,
        this.baseContent(),
        this.showSignIn(),
        this.showToast(!!this.state.showToast, this.state.toastMessage, this.state.toastColor),
      ),
    );
  }
}

const mapStateToProps = (
  state: any,
): {
  authProvider: CustomerAuthProvider;
} => {
  return {
    authProvider: state.org.customerAuthProvider,
  };
};

export default connect(mapStateToProps)(PasswordResetPage);
