import React, { Component } from 'react';
import type { ConnectedComponent } from 'react-redux';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router';

import { ApiResource, ApiVersion } from '@biteinc/enums';

import { MaitredClient } from '../clients';
import { setCustomer } from '../reducers';
import { Store } from '../store';

export interface ProtectRouteConfig {
  component: ConnectedComponent<any, any> | typeof Component<any>;
  path: string;
  location: any;
  exact?: boolean;
}

interface ProtectedRouteState {
  isLoggedIn: boolean | undefined;
}

class ProtectedRoute extends Component<ProtectRouteConfig, ProtectedRouteState> {
  constructor(props: ProtectRouteConfig) {
    super(props);

    this.state = {
      isLoggedIn: undefined,
    };
  }

  private async isLoggedIn(): Promise<void> {
    const url = MaitredClient.generateUrl(ApiVersion.V2, ApiResource.Customer, '');
    const result = await MaitredClient.get(url);
    if (result.success !== this.state.isLoggedIn) {
      this.setState({ isLoggedIn: result.success });
    }
    /**
     * @todo Probably want to move this somewhere else in the future
     */
    if (result.success) {
      Store.dispatch(setCustomer(result.customer));
    } else {
      Store.dispatch(setCustomer(null));
    }
  }

  async componentDidMount(): Promise<void> {
    await this.isLoggedIn();
  }

  shouldComponentUpdate(): boolean {
    void this.isLoggedIn();
    return true;
  }

  /**
   * Only redirect once we have state
   */
  render(): React.ReactNode {
    if (this.state.isLoggedIn) {
      return React.createElement(Route, {
        path: this.props.path,
        exact: this.props.exact,
        component: this.props.component as unknown as React.FC,
      });
    }
    if (this.state.isLoggedIn === undefined) {
      return null;
    }
    return React.createElement(Redirect, { to: `/${MaitredClient.getScope()}/auth/login` });
  }
}

const mapStateToProps = (
  state: any,
): {
  location: any;
} => {
  return {
    location: state.location,
  };
};

export default connect(mapStateToProps)(ProtectedRoute);
