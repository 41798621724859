import async from 'async';
import axios from 'axios';
import _ from 'lodash';

import { BiteCustomerAccountsScope, ErrorCode } from '@biteinc/common';
import type { ApiResource, ApiVersion } from '@biteinc/enums';
import { ApiHeader, ClientApiVersion } from '@biteinc/enums';

import { showLoading } from '../reducers';
import { Store } from '../store';
import * as Storage from './local-storage';

axios.defaults.baseURL = Store.getState().apiHost;

export enum RequestMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
}

export interface MaitredRequestOptions {
  method: RequestMethod;
  resource: string;
  body?: any;
  params?: { [key: string]: string | number | boolean | number[] | null };
}

export class MaitredClient {
  static getScope(): string {
    return Store.getState().scope || BiteCustomerAccountsScope;
  }

  static getTokenKey(): string {
    return `${MaitredClient.getScope()}:token`;
  }

  static getAuthToken(): string {
    return Storage.getItem(MaitredClient.getTokenKey()) as string;
  }

  static getAuthRedirectUrl(): string {
    const state = Store.getState();
    return `${state.flashResolvedHost}/${MaitredClient.getScope()}/auth/login`;
  }

  static getLocationId(): string | undefined {
    const state = Store.getState();
    return state.location?._id;
  }

  static getOrgId(): string {
    const state = Store.getState();
    return state.org._id;
  }

  static getOrderChannel(): string {
    const state = Store.getState();
    return state.orderChannel;
  }

  static generateUrl(version: ApiVersion, resource: ApiResource, path = ''): string {
    const parts = [version, resource, path];
    return `/${parts
      .filter((part: string) => {
        return part;
      })
      .join('/')}`;
  }

  static async makeRequestWithLoadingSpinner(opts: MaitredRequestOptions): Promise<any> {
    Store.dispatch(showLoading(true));
    const result = await this.makeRequest(opts);
    Store.dispatch(showLoading(false));
    return result;
  }

  static async waitForJob(queuePath: string): Promise<{
    jobState: string;
    result: any;
    success: boolean;
  }> {
    let attempts = 0;
    // eslint-disable-next-line @typescript-eslint/await-thenable
    const response = (await async.doUntil(
      (cb) => {
        // 0, 1, 2, 4, 8, 16, 16, 16...
        const delay = attempts === 0 ? 0 : 1000 * Math.min(16, Math.pow(2, attempts - 1));
        setTimeout(() => {
          MaitredClient.get(queuePath)
            .then((data) => {
              cb(null, data);
            })
            .catch((err) => {
              // Only consider it a fatal error if the server tells us there's no job
              if (err?.code === 404) {
                cb(err);
                return;
              }
              cb();
            });
        }, delay);
      },
      // @ts-expect-error async types aren't great here
      (res: any, cb: Function) => {
        attempts++;
        // response could be undefined for scenarios like network timeouts
        cb(null, _.includes(['completed', 'failed'], res?.jobState));
      },
    )) as unknown as { jobState: string; result: any; success: boolean };
    // bad types on async doUntil
    return response;
  }

  static async makeRequest(opts: MaitredRequestOptions): Promise<any> {
    const scope = MaitredClient.getScope();
    const token = MaitredClient.getAuthToken();
    const headers = {
      [ApiHeader.ApiVersion]: `${ClientApiVersion.SupportsErrorMessage}`,
      [ApiHeader.CustomerAppScope]: scope,
      [ApiHeader.OrgId]: MaitredClient.getOrgId(),
      [ApiHeader.OrderChannel]: MaitredClient.getOrderChannel(),
      ...(MaitredClient.getLocationId() && {
        [ApiHeader.LocationId]: MaitredClient.getLocationId(),
      }),
      ...(token && { [ApiHeader.CustomerToken]: token }),
    };

    return axios
      .request({
        method: opts.method,
        url: opts.resource,
        headers,
        ...(opts.body && { data: opts.body }),
        ...(opts.params && { params: opts.params }),
      })
      .then(({ data }) => {
        // Keep the success of the data
        return {
          ...(data?.data && data.data),
          success: data?.success,
        };
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          if (err.response?.data?.code === ErrorCode.AuthInvalidCustomerToken) {
            Storage.removeItem(MaitredClient.getTokenKey());
            window.location.href = MaitredClient.getAuthRedirectUrl();
          }
        }

        return {
          status: err.response?.status,
          success: false,
          message: err.response?.message || 'Oops! Something went wrong.',
          ...(err.response?.data && err.response?.data), // overwrite message
        };
      });
  }

  static get(resource: string, withLoading?: boolean): Promise<any> {
    if (withLoading) {
      return MaitredClient.makeRequestWithLoadingSpinner({ method: RequestMethod.GET, resource });
    }
    return MaitredClient.makeRequest({ method: RequestMethod.GET, resource });
  }

  static post(resource: string, body: any, withLoading?: boolean): Promise<any> {
    if (withLoading) {
      return MaitredClient.makeRequestWithLoadingSpinner({
        method: RequestMethod.POST,
        resource,
        body,
      });
    }
    return MaitredClient.makeRequest({ method: RequestMethod.POST, resource, body });
  }

  static put(resource: string, body: any, withLoading?: boolean): Promise<any> {
    const options = {
      method: RequestMethod.PUT,
      resource,
      body,
    };

    if (withLoading) {
      return MaitredClient.makeRequestWithLoadingSpinner(options);
    }
    return MaitredClient.makeRequest(options);
  }

  static delete(resource: string, id: string, body?: any, withLoading?: boolean): Promise<any> {
    const options = {
      method: RequestMethod.DELETE,
      resource: `${resource}/${id}`,
      ...(body && { body }),
    };
    if (withLoading) {
      return MaitredClient.makeRequestWithLoadingSpinner(options);
    }
    return MaitredClient.makeRequest(options);
  }
}
