import { IonLoading } from '@ionic/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import type { Unsubscribe } from 'redux';

import { Store } from '../../store';

class LoadingSpinner extends Component<any, any> {
  private readonly DELAY_TIME = 500;

  private subscription: Unsubscribe;

  private loadingStarted: number | null = null;

  constructor(props: any) {
    super(props);
    this.state = {
      showLoading: props.showLoading,
    };
    this.subscription = Store.subscribe(this.checkLoading.bind(this));
  }

  /**
   * We need to check if loading is triggered since a fast api response will cause the spinner
   * to remain in place
   */
  private checkLoading(): void {
    const { loading } = Store.getState();
    if (loading) {
      this.loadingStarted = Date.now();
    } else {
      const diff = Date.now() - (this.loadingStarted ?? Date.now());
      if (diff < this.DELAY_TIME) {
        setTimeout(() => {
          this.setState({ showLoading: loading });
          this.loadingStarted = null;
        }, this.DELAY_TIME);
        return;
      }
      this.loadingStarted = null;
    }
    this.setState({ showLoading: loading });
  }

  render(): React.ReactNode {
    return React.createElement(IonLoading, {
      isOpen: this.state.showLoading,
      message: 'Loading...',
    });
  }
}

const mapStateToProps = (
  state: any,
): {
  showLoading: boolean;
} => {
  return {
    showLoading: state.loading,
  };
};

export default connect(mapStateToProps)(LoadingSpinner);
