import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonText,
} from '@ionic/react';
import _ from 'lodash';
import React from 'react';

import { LocalizationHelper } from '@biteinc/core-react';
import type { CustomerPaymentMethod } from '@biteinc/core-react';
import { ApiResource, ApiVersion } from '@biteinc/enums';

import { MaitredClient } from '../../clients';
import type { BaseState } from '../../components';
import { BaseComponent, ConfirmAlert } from '../../components';
import ResponsiveContent from '../../layout/responsive-content/responsive-content';
import { PaymentFormModal } from '../../modals';

import './payments-page.scss';

interface PaymentsPageProps {}

interface PaymentsPageState extends BaseState {
  showConfirm: boolean;
  toastMessage: string | null;
  toastColor: string | null;
  showPaymentFormModal: boolean;
  showEditAlert: boolean;
  paymentMethods: CustomerPaymentMethod[];
}

class PaymentsPage extends BaseComponent<PaymentsPageProps, PaymentsPageState> {
  private readonly expiredMessage = 'Your card has expired';

  private readonly expiringMessage = 'Your card is expiring soon';

  private editingCard?: {
    paymentMethod: CustomerPaymentMethod;
    idx: number;
  } | null;

  constructor(props: PaymentsPageProps) {
    super(props);

    this.state = {
      showConfirm: false,
      showToast: false,
      toastMessage: null,
      toastColor: null,
      showPaymentFormModal: false,
      showEditAlert: false,
      paymentMethods: [],
    };
  }

  showEditAlert(): React.ReactNode {
    if (!this.state.showEditAlert) {
      return undefined;
    }

    return React.createElement(IonAlert, {
      isOpen: true,
      header: this.getCardDescription(this.editingCard?.paymentMethod),
      onDidDismiss: () => {
        this.setState({ showEditAlert: false });
      },
      inputs: [
        {
          name: 'nickname',
          type: 'text',
          placeholder: 'Card nickname',
        },
      ],
      buttons: [
        {
          text: 'Delete',
          cssClass: 'danger',
          handler: () => {
            this.setState({ showConfirm: true });
          },
        },
        {
          text: 'Edit',
          cssClass: 'primary',
          handler: (data: { nickname: string }) => {
            if (_.size(data?.nickname) > 20) {
              this.setState({
                showToast: true,
                toastMessage: 'Max nickname length is 20.',
                toastColor: 'danger',
              });
            } else if (data?.nickname) {
              void this.updateNickname(data.nickname);
            }
          },
        },
      ],
    });
  }

  getPaymentFormModal(): React.ReactNode {
    if (this.state.showPaymentFormModal) {
      return React.createElement(PaymentFormModal, {
        onCardSaved: (paymentMethod: CustomerPaymentMethod) => {
          this.cardSaved(paymentMethod);
        },
        onClose: () => {
          this.setState({ showPaymentFormModal: false });
        },
      });
    }
    return undefined;
  }

  cardSaved(paymentMethod: CustomerPaymentMethod): void {
    this.setState({
      showToast: true,
      toastMessage: 'Card added successfully',
      toastColor: 'success',
      showPaymentFormModal: false,
      paymentMethods: [...this.state.paymentMethods, paymentMethod],
    });
  }

  addCard(): void {
    this.setState({ showPaymentFormModal: true });
  }

  generateUrl(): string {
    return MaitredClient.generateUrl(ApiVersion.V2, ApiResource.Customer, 'payment-methods');
  }

  async componentDidMount(): Promise<void> {
    const url = this.generateUrl();
    const result = await MaitredClient.get(url, true);
    if (result?.success) {
      this.setState({
        paymentMethods: result.paymentMethods,
      });
    }
  }

  private edit(paymentMethod: CustomerPaymentMethod, idx: number): void {
    this.editingCard = { paymentMethod, idx };
    this.setState({ showEditAlert: true });
  }

  private async delete(): Promise<void> {
    if (!this.editingCard) {
      return;
    }
    const url = this.generateUrl();
    const result = await MaitredClient.delete(url, this.editingCard.paymentMethod._id, true);
    if (result?.success) {
      const copy = this.state.paymentMethods.slice();
      copy.splice(this.editingCard.idx, 1);
      this.editingCard = null;
      this.setState({ paymentMethods: copy });
    }
  }

  private async updateNickname(nickname: string): Promise<void> {
    if (!this.editingCard) {
      return;
    }
    const resource = `${this.generateUrl()}/${this.editingCard.paymentMethod._id}`;
    const result = await MaitredClient.put(resource, { nickname }, true);
    if (result?.success) {
      const copy = this.state.paymentMethods.slice();
      copy[this.editingCard.idx].nickname = nickname;
      this.editingCard = null;
      this.setState({ paymentMethods: copy });
    }
  }

  private getNicknamePart(nickname?: string): string {
    if (!nickname) {
      return '';
    }
    return `${nickname} - `;
  }

  private getCardDescription(paymentMethod?: CustomerPaymentMethod): string {
    if (!paymentMethod) {
      return '';
    }
    return `${this.getNicknamePart(paymentMethod.nickname)}${this.localize(
      LocalizationHelper.localizeEnum.CardSchemeId(paymentMethod.cardSchemeId),
    )} *${paymentMethod.last4}`;
  }

  private createExpiredElement(message: string): React.ReactNode {
    return React.createElement(
      'p',
      null,
      React.createElement(IonText, { color: 'danger' }, message),
    );
  }

  private getExpiryNotice(paymentMethod: CustomerPaymentMethod): React.ReactNode {
    const date = new Date();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const expirationYear = paymentMethod.expirationYear + 2000;
    const expirationMonth = paymentMethod.expirationMonth;
    if (expirationYear < year) {
      return this.createExpiredElement(this.expiredMessage);
    }
    if (expirationYear === year) {
      if (expirationMonth === month) {
        return this.createExpiredElement(this.expiringMessage);
      }
      if (expirationMonth < month) {
        return this.createExpiredElement(this.expiredMessage);
      }
    }
    return undefined;
  }

  getPaymentMethodList(): React.ReactNode {
    const listItems: React.ReactNode[] = [];
    this.state.paymentMethods.forEach((paymentMethod, idx) => {
      listItems.push(
        React.createElement(
          IonItem,
          { className: 'saved-card-list-item' },
          React.createElement(
            IonLabel,
            null,
            this.getCardDescription(paymentMethod),
            this.getExpiryNotice(paymentMethod),
          ),
          React.createElement(
            IonButton,
            {
              slot: 'end',
              size: 'small',
              color: 'primary',
              onClick: () => {
                this.edit(paymentMethod, idx);
              },
            },
            'Edit',
          ),
        ),
      );
    });
    return React.createElement(IonList, { lines: 'full' }, ...listItems);
  }

  showCardDeletionConfirm(): React.ReactNode {
    return React.createElement(ConfirmAlert, {
      show: this.state.showConfirm,
      message: 'Are you sure you want to delete this card?',
      onConfirm: () => {
        this.setState({ showConfirm: false });
        void this.delete();
      },
      onCancel: () => {
        this.setState({ showConfirm: false });
      },
    });
  }

  render(): React.ReactNode {
    return React.createElement(
      IonContent,
      { className: 'ion-padding' },
      React.createElement(
        ResponsiveContent,
        null,
        React.createElement(
          IonRow,
          null,
          React.createElement(
            IonCol,
            null,
            React.createElement('h1', { className: 'ion-text-center' }, 'Your Saved Cards'),
          ),
        ),
        React.createElement(
          IonRow,
          null,
          React.createElement(IonCol, null, this.getPaymentMethodList()),
        ),
        React.createElement(
          IonRow,
          null,
          React.createElement(
            IonCol,
            null,
            React.createElement(
              IonButton,
              { expand: 'block', color: 'secondary', onClick: this.addCard.bind(this) },
              'Add Credit Card',
            ),
          ),
        ),
      ),
      this.showEditAlert(),
      this.getPaymentFormModal(),
      this.showToast(!!this.state.showToast, this.state.toastMessage, this.state.toastColor),
      this.showCardDeletionConfirm(),
    );
  }
}

export default PaymentsPage;
