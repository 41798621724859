import { IonContent, IonLabel, IonRow, IonSegment, IonSegmentButton } from '@ionic/react';
import React from 'react';

import { Strings } from '@biteinc/common';
import type { CustomerFavorites, CustomerOrder } from '@biteinc/core-react';
import { ApiResource, ApiVersion } from '@biteinc/enums';

import { MaitredClient } from '../../clients';
import type { BaseState } from '../../components';
import { BaseComponent } from '../../components';
import ResponsiveContent from '../../layout/responsive-content/responsive-content';
import { FavoritesList, OrdersList } from './components';

interface OrdersPageProps {}

interface OrdersPageState extends BaseState {
  orders: CustomerOrder[];
  favorites: CustomerFavorites[];
  segment: 'orders' | 'favorites';
}
class OrdersPage extends BaseComponent<OrdersPageProps, OrdersPageState> {
  constructor(props: OrdersPageProps) {
    super(props);

    this.state = {
      favorites: [],
      orders: [],
      segment: 'orders',
    };
  }

  async getOrders(): Promise<void> {
    const url = MaitredClient.generateUrl(ApiVersion.V2, ApiResource.Customer, 'orders');
    const result = await MaitredClient.get(url, true);
    if (result?.success) {
      this.setState({
        orders: result.orders,
      });
    }
  }

  async getFavorites(): Promise<void> {
    const url = MaitredClient.generateUrl(ApiVersion.V2, ApiResource.Customer, 'favorites');
    const result = await MaitredClient.get(url);
    if (result?.success) {
      this.setState({
        favorites: result.favorites,
      });
    }
  }

  async delete(favoriteId: string, orderedItemId: string, idx: number): Promise<void> {
    const url = MaitredClient.generateUrl(ApiVersion.V2, ApiResource.Customer, 'favorites');
    const result = await MaitredClient.delete(url, favoriteId, { orderedItemId }, true);
    if (result?.success) {
      const favoritesCopy = this.state.favorites.slice();
      const favoriteLocationIdx = favoritesCopy.findIndex((fav) => {
        return fav._id === favoriteId;
      });
      const locationToDelete = favoritesCopy[favoriteLocationIdx];
      locationToDelete.orderedItems.splice(idx, 1);
      this.setState({
        favorites: favoritesCopy,
      });
    }
  }

  setSegment(e: any): void {
    this.setState({ segment: e.detail.value });
  }

  getSegmentView(): React.ReactNode {
    if (this.state.segment === 'orders') {
      return React.createElement(OrdersList, { orders: this.state.orders });
    }
    return React.createElement(FavoritesList, {
      favorites: this.state.favorites,
      onDelete: this.delete.bind(this),
    });
  }

  componentDidMount(): void {
    void this.getOrders();
    void this.getFavorites();
  }

  render(): React.ReactNode {
    return React.createElement(
      IonContent,
      { className: 'ion-padding' },
      React.createElement(
        ResponsiveContent,
        null,
        React.createElement(
          IonSegment,
          { value: this.state.segment, onIonChange: this.setSegment.bind(this) },
          React.createElement(
            IonSegmentButton,
            { value: 'orders' },
            React.createElement(IonLabel, null, this.localize(Strings.ORDER_HISTORY)),
          ),
          React.createElement(
            IonSegmentButton,
            { value: 'favorites' },
            React.createElement(IonLabel, null, this.localize(Strings.FAVORITES)),
          ),
        ),
        React.createElement(IonRow, null, this.getSegmentView()),
      ),
    );
  }
}

export default OrdersPage;
