import React, { Component } from 'react';

interface TextInputProps {
  value: string;
  placeholder: string;
  change(value: string): void;
  hidden?: boolean;
  description?: string;
  label?: string;
}

export default class TextInput extends Component<TextInputProps> {
  handleChange(event: any): void {
    if (this.props.change) {
      this.props.change(event.target.value);
    }
  }

  render(): React.ReactNode {
    let label;
    if (this.props.label) {
      label = React.createElement('label', null, this.props.label);
    }
    let description;
    if (this.props.description) {
      description = React.createElement('p', null, this.props.description);
    }
    return (
      <div
        className="form-input"
        hidden={this.props.hidden}
      >
        {label}
        <input
          type="text"
          placeholder={this.props.placeholder}
          value={this.props.value}
          onChange={this.handleChange.bind(this)}
        ></input>
        {description}
      </div>
    );
  }
}
