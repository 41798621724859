import { IonButton, IonCol, IonContent, IonPage, IonRow } from '@ionic/react';
import qs from 'qs';
import React from 'react';
import { connect } from 'react-redux';

import { Strings } from '@biteinc/common';
import { Header } from '@biteinc/core-react';
import { ApiResource, ApiVersion } from '@biteinc/enums';

import { MaitredClient, RequestMethod } from '../../clients';
import { BaseComponent, Logo } from '../../components';
import { headerConnector } from '../../connectors';
import ResponsiveContent from '../../layout/responsive-content/responsive-content';

class VerifyAccountPage extends BaseComponent<any, any> {
  resource: ApiResource = ApiResource.Customer;

  version: ApiVersion = ApiVersion.V2;

  path = 'verify-account';

  method = RequestMethod.POST;

  constructor(props: any) {
    super(props);
    this.state = {
      data: {
        verifyToken: null,
      },
      success: false,
      successMessage: null,
      showToast: false,
      toastMessage: null,
      toastColor: null,
    };
  }

  async submit(): Promise<void> {
    const url = MaitredClient.generateUrl(this.version, this.resource, this.path);
    const result = await MaitredClient.makeRequestWithLoadingSpinner({
      method: this.method,
      resource: url,
      body: this.state.data,
    });
    if (result?.success) {
      this.setState({
        success: true,
        successMessage: result.message,
      });
    } else {
      this.setState({
        showToast: true,
        toastColor: 'danger',
        toastMessage: result.message,
      });
    }
  }

  generateUi(): React.ReactNode {
    if (this.state.success) {
      return React.createElement(
        IonCol,
        null,
        React.createElement('p', { className: 'ion-text-center' }, this.state.successMessage),
        React.createElement(
          IonButton,
          {
            color: 'primary',
            onClick: () => {
              this.props.history.push(`/${MaitredClient.getScope()}/auth/login`);
            },
          },
          this.localize(Strings.CUSTOMER_ACCOUNT_BUTTON_LOG_IN),
        ),
      );
    }
    return React.createElement(
      IonCol,
      null,
      React.createElement(
        'p',
        { className: 'ion-text-center' },
        this.localize(Strings.VERIFY_ACCOUNT_CTA),
      ),
      React.createElement(
        IonButton,
        { color: 'primary', onClick: this.submit.bind(this) },
        this.localize(Strings.VERIFY_ACCOUNT),
      ),
    );
  }

  componentDidMount(): void {
    const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    if (query.vt) {
      this.setState({
        data: {
          verifyToken: query.vt,
        },
      });
    }
  }

  render(): React.ReactNode {
    return React.createElement(
      IonPage,
      null,
      React.createElement(headerConnector(Header)),
      React.createElement(
        IonContent,
        { className: 'ion-padding' },
        React.createElement(
          ResponsiveContent,
          null,
          React.createElement(Logo, null),
          React.createElement(IonRow, null, this.generateUi()),
        ),
        this.showToast(this.state.showToast, this.state.toastMessage, this.state.toastColor),
      ),
    );
  }
}

const mapStateToProps = (
  state: any,
): {
  host: string;
} => {
  return {
    host: state.flashResolvedHost,
  };
};

export default connect(mapStateToProps)(VerifyAccountPage);
