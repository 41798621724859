import { combineReducers, createStore } from 'redux';

import { BiteCustomerAccountsScope } from '@biteinc/common';
import { LanguageCode, OrderChannel } from '@biteinc/enums';

import {
  ApiReducer,
  CustomerReducer,
  LoadingReducer,
  LocationReducer,
  MenuReducer,
} from '../reducers';
import type { Appearance, Org } from '../types';

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.__PRELOADED_STATE__;

// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__;

export const rootReducer = combineReducers({
  customer: CustomerReducer,
  location: LocationReducer,
  scope: (scope: string = BiteCustomerAccountsScope) => scope,
  // @ts-expect-error
  org: (org: Org = {}) => org,
  orderChannel: (orderChannel: OrderChannel = OrderChannel.Flash) => orderChannel,
  language: (language: LanguageCode = LanguageCode.EN_US) => language,
  // @ts-expect-error
  appearance: (appearance: Appearance = {}) => appearance,
  loading: LoadingReducer,
  menu: MenuReducer,
  apiHost: ApiReducer,
  flashResolvedHost: ApiReducer,
  env: (state: string = 'dev') => state,
  googleApiKey: (state: string = '') => state,
  canStorePaymentMethods: (state: boolean = true) => state,
});

/**
 * LocationStore
 * - subscribe to updates
 * - dispatch actions {LocationAction}
 */
const store = createStore(rootReducer, preloadedState);

export default store;
