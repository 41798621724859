import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import React, { Component } from 'react';

import type { CustomerPaymentMethod } from '@biteinc/core-react';

import { FreedomPayCardForm } from '../../components';

interface PaymentFormModalProps {
  onCardSaved(paymentMethod: CustomerPaymentMethod): void;
  onClose(): void;
}

class PaymentFormModal extends Component<PaymentFormModalProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      showModal: true,
      component: null,
    };
  }

  private close(): void {
    this.setState({ showModal: false });
    this.props.onClose();
  }

  onSubmit(card: any): void {
    this.close();
    this.props.onCardSaved(card);
  }

  render(): React.ReactNode {
    return React.createElement(IonModal, {
      isOpen: this.state.showModal,
      presentingElement: undefined,
      className: 'payment-form-modal',
      swipeToClose: true,
      children: [
        React.createElement(
          IonHeader,
          null,
          React.createElement(
            IonToolbar,
            null,
            React.createElement(
              IonButtons,
              { slot: 'start' },
              React.createElement(
                IonButton,
                { onClick: this.close.bind(this) },
                React.createElement(IonIcon, { slot: 'icon-only', icon: close }),
              ),
            ),
            React.createElement(IonTitle, null, 'Add a New Card'),
          ),
        ),
        React.createElement(FreedomPayCardForm, { onSubmit: this.onSubmit.bind(this) }),
      ],
    });
  }
}

export default PaymentFormModal;
