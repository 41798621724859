import { setupIonicReact } from '@ionic/react';
import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './app';
import { Store } from './store';

import './index.scss';

setupIonicReact({
  mode: 'ios',
});

const env = Store.getState().env;

if (!['dev', 'test'].includes(env)) {
  Sentry.init({
    dsn: 'https://acc98051abea45efae678c9455ed4f89@sentry.io/132234',
    integrations: [Sentry.rewriteFramesIntegration()],
    release: process.env.npm_package_version,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
