import type React from 'react';

import type { LocationSearchResult } from '../types';

type MapMarketOptions = {
  map: google.maps.Map;
  coordinates: number[];
  setAsCenter: boolean;
  iconUrl?: string;
};

/**
 * @description This class is not usable until the maps script is loaded.
 * So we prevent any consumers from directly instantiating this class and forcing them instead to
 * call init() which will asynchronously load the script (as a singleton) and only then create the
 * client class
 */
export default class GoogleMapsClient {
  private static googleMapsScript?: HTMLScriptElement;

  private static googleMapsScriptHasLoaded: boolean = false;

  private constructor() {}

  public static async init(apiKey: string): Promise<GoogleMapsClient> {
    await this.loadScript(apiKey);
    return new GoogleMapsClient();
  }

  private static newScriptWaitingPromise(googleMapsScript: HTMLScriptElement): Promise<void> {
    return new Promise((resolve) => {
      googleMapsScript.addEventListener('load', () => {
        this.googleMapsScriptHasLoaded = true;
        resolve();
      });
    });
  }

  private static async loadScript(apiKey: string): Promise<void> {
    if (this.googleMapsScriptHasLoaded) {
      return;
    }
    if (this.googleMapsScript) {
      return this.newScriptWaitingPromise(this.googleMapsScript);
    }

    const googleMapsScriptUrl = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;

    // Check if script exists already
    if (document.querySelectorAll(`script[src="${googleMapsScriptUrl}"]`).length > 0) {
      return Promise.resolve();
    }

    this.googleMapsScript = document.createElement('script');
    this.googleMapsScript.src = googleMapsScriptUrl;

    document.body.appendChild(this.googleMapsScript);

    return this.newScriptWaitingPromise(this.googleMapsScript);
  }

  public createMap(
    elem: React.RefObject<any>,
    opts: Partial<google.maps.MapOptions>,
  ): google.maps.Map {
    return new google.maps.Map(elem.current, {
      zoom: 10,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      styles: [
        {
          featureType: 'poi',
          elementType: 'all',
          stylers: [{ visibility: 'off' }],
        },
      ],
      ...opts,
    });
  }

  public createMarker(options: MapMarketOptions): google.maps.Marker {
    const { map, coordinates, setAsCenter, iconUrl } = options;
    const gCoords = { lat: coordinates[1], lng: coordinates[0] };
    if (setAsCenter) {
      map.setCenter(gCoords);
    }
    const icon = {
      url: iconUrl || 'https://assets.getbite.com/images-default/bite_carrot_x32.png',
      scaledSize: new google.maps.Size(36, 36), // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(0, 0), // anchor
    };
    return new google.maps.Marker({
      position: gCoords,
      map,
      icon,
    });
  }

  public createLocationInfoWindowContainer(location: LocationSearchResult): google.maps.InfoWindow {
    const contentString = `
      <div id="location-info-window-${location._id}" class="location-info-window">
      </div>
    `;
    return new google.maps.InfoWindow({
      content: contentString,
    });
  }

  public addListener(element: object, event: string, callback: () => void): void {
    google.maps.event.addListener(element, event, callback);
  }

  public getAddressPredictions(address: string, callback: (predictions: string[]) => void): void {
    const autoCompleteService = new google.maps.places.AutocompleteService();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    autoCompleteService.getPlacePredictions({ input: address }, (results) => {
      const addressPredictions = results?.map((locationResult) => {
        return locationResult.description;
      });
      callback(addressPredictions || []);
    });
  }
}
