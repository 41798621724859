import { Strings } from '@biteinc/common';
import type { Schema } from '@biteinc/core-react';
import {
  FormLabelPosition,
  LocalizationHelper,
  SchemaType,
  ValidatorFactory,
} from '@biteinc/core-react';

export const profileEditSchema: Schema = {
  email: {
    type: SchemaType.EMAIL,
    label: {
      text: LocalizationHelper.closure(Strings.EMAIL_ADDRESS),
      position: FormLabelPosition.FLOATING,
    },
    immutable: true,
  },
  passwordReset: {
    type: SchemaType.BUTTON,
    label: {
      text: LocalizationHelper.closure(Strings.PASSWORD),
      position: FormLabelPosition.STACKED,
    },
    button: {
      label: LocalizationHelper.closure(Strings.CHANGE_PASSWORD),
      path: 'account/profile/reset-password',
    },
  },
  firstName: {
    type: SchemaType.STRING,
    label: {
      text: LocalizationHelper.closure(Strings.FIRST_NAME),
      position: FormLabelPosition.FLOATING,
    },
    required: true,
  },
  lastName: {
    type: SchemaType.STRING,
    label: {
      text: LocalizationHelper.closure(Strings.LAST_NAME),
      position: FormLabelPosition.FLOATING,
    },
    required: true,
  },
  phoneNumber: {
    type: SchemaType.PHONE_NUMBER,
    label: {
      text: LocalizationHelper.closure(Strings.PHONE_NUMBER),
      position: FormLabelPosition.FLOATING,
    },
    validators: [ValidatorFactory.displayPhoneNumber()],
  },
};
