import { IonAlert } from '@ionic/react';
import React, { Component } from 'react';

export interface ConfirmAlertProps {
  show: boolean;
  message: string;
  onConfirm: Function;
  onCancel: Function;
  confirmText?: string;
  cancelText?: string;
}

class ConfirmAlert extends Component<ConfirmAlertProps, any> {
  render(): JSX.Element {
    return React.createElement(IonAlert, {
      isOpen: this.props.show,
      message: this.props.message,
      onDidDismiss: () => {
        this.setState({ showDialog: false });
      },
      buttons: [
        {
          text: this.props.cancelText || 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            this.props.onCancel();
          },
        },
        {
          text: this.props.confirmText || 'Okay',
          handler: () => {
            this.props.onConfirm();
          },
        },
      ],
    });
  }
}

export default ConfirmAlert;
