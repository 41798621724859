import { IonButton, IonCheckbox, IonInput, IonItem, IonLabel, IonList } from '@ionic/react';
import _ from 'lodash';
import React from 'react';
import type { RouteComponentProps } from 'react-router';

import type { CustomStringMap } from '@biteinc/common';
import { Strings } from '@biteinc/common';
import type {
  FormLabel,
  FormLabelPosition,
  OnSubmit,
  Schema,
  SchemaProperties,
  ValidationResult,
  ValidatorFunction,
} from '@biteinc/core-react';
import { LocalizationHelper, SchemaType } from '@biteinc/core-react';
import type { ApiResource, ApiVersion, LanguageCode } from '@biteinc/enums';

import { MaitredClient, RequestMethod } from '../clients';
import type { BaseState } from '../components/base-component';
import { BaseComponent } from '../components/base-component';

export interface FormState<Data = any> extends BaseState {
  data: Data;
}

export interface FormProps extends RouteComponentProps<any> {}

export interface FormValidationResult {
  isValid: boolean;
  message: LocalizationHelper.Closure;
}

class BaseFormComponent<Props extends FormProps, State extends FormState>
  extends BaseComponent<Props, State>
  implements OnSubmit
{
  readonly resource!: ApiResource;

  readonly version!: ApiVersion;

  readonly path!: string;

  schema!: Schema;

  readonly method: RequestMethod = RequestMethod.POST;

  private testValidators(
    value: any,
    validators: ValidatorFunction[],
  ): { isValid: boolean; errors: LocalizationHelper.Closure[] } {
    const result: { isValid: boolean; errors: LocalizationHelper.Closure[] } = {
      isValid: true,
      errors: [],
    };
    validators.forEach((validator: Function) => {
      const validation: ValidationResult = validator(value);
      if (!validation.isValid) {
        result.isValid = false;
        result.errors.push(validation.error);
      }
    });
    return result;
  }

  protected isFormValid(): FormValidationResult {
    return Object.keys(this.schema).reduce(
      (prev: FormValidationResult, key: string) => {
        const prop = this.schema[key];
        if (prop.notIf && this.state.data[prop.notIf]) {
          return prev;
        }
        if (!this.state.data[key] && prop.required) {
          prev.isValid = false;
          const messageClosure: LocalizationHelper.Closure = (
            lang: LanguageCode,
            customStrings: CustomStringMap,
          ) => {
            const label = prop.label?.text ? prop.label.text(lang, customStrings) : key;
            return LocalizationHelper.closure(Strings.VALIDATION_MESSAGE_FIELD_IS_REQUIRED, [
              label,
            ])(lang, customStrings);
          };
          prev.message = LocalizationHelper.combineClosures([prev.message, messageClosure]);
          return prev;
        }
        if (_.size(prop.validators)) {
          const { isValid, errors } = this.testValidators(this.state.data[key], prop.validators);
          if (!isValid) {
            prev.isValid = false;
            prev.message = LocalizationHelper.combineClosures([prev.message, ...errors]);
          }
        }
        return prev;
      },
      { isValid: true, message: LocalizationHelper.closure('') },
    );
  }

  handleInputChange(event: any): void {
    const target = event.target;
    const value = target.role === 'checkbox' ? event.detail.checked : target.value;
    const name = target.name;
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  }

  async handlePhoneInputChange(event: any): Promise<void> {
    const name = event.target.name;
    const value = (event.target.value || '').replaceAll(/[^0-9]/g, '');
    const $el = await event.target.getInputElement();
    $el.value = value;
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  }

  async submit(): Promise<any> {
    const formValidation = this.isFormValid();
    if (!formValidation.isValid) {
      return {
        success: false,
        message: this.localizeClosure(formValidation.message),
      };
    }
    const url = MaitredClient.generateUrl(this.version, this.resource, this.path);
    return MaitredClient.makeRequestWithLoadingSpinner({
      method: this.method,
      resource: url,
      body: _.pickBy(this.state.data, _.identity),
    });
  }

  getInputFromProps(prop: string, input: SchemaProperties): React.ReactNode {
    if (input.notIf && this.state.data[input.notIf]) {
      return null;
    }
    switch (input.type) {
      case SchemaType.ARRAY:
      case SchemaType.DATE:
      case SchemaType.OBJECT:
        return null;
      case SchemaType.BUTTON:
        return React.createElement(
          IonButton,
          {
            expand: 'block',
            color: 'secondary',
            onClick: () => {
              this.props.history.push(`/${MaitredClient.getScope()}/${input.button?.path}`);
            },
            ...(input.inputClassName && { className: input.inputClassName }),
          },
          this.localizeClosure(input.button.label),
        );
      case SchemaType.BOOLEAN:
        return React.createElement(IonCheckbox, {
          slot: 'start',
          name: prop,
          value: this.state.data[prop],
          checked: this.state.data[prop],
          onIonChange: this.handleInputChange.bind(this),
          ...(input.immutable && { disabled: true }),
          ...(input.inputClassName && { className: input.inputClassName }),
        });
      case SchemaType.PHONE_NUMBER:
        return React.createElement(IonInput, {
          type: input.type,
          name: prop,
          ...(input.placeholder && { placeholder: input.placeholder }),
          value: this.state.data[prop],
          onIonChange: this.handlePhoneInputChange.bind(this),
          ...(input.immutable && { disabled: true }),
          ...(input.autocomplete && { autocomplete: input.autocomplete }),
          ...(input.inputClassName && { className: input.inputClassName }),
        });
      case SchemaType.EMAIL:
      case SchemaType.NUMBER:
      case SchemaType.PASSWORD:
      case SchemaType.STRING:
        return React.createElement(IonInput, {
          type: input.type,
          name: prop,
          ...(input.placeholder && { placeholder: input.placeholder }),
          value: this.state.data[prop],
          onIonInput: this.handleInputChange.bind(this),
          ...(input.immutable && { disabled: true }),
          ...(input.autocomplete && { autocomplete: input.autocomplete }),
          ...(input.inputClassName && { className: input.inputClassName }),
        });
    }
  }

  generateLabel(label?: FormLabel): React.ReactNode {
    if (label?.text) {
      const props: { position?: FormLabelPosition } = {};
      if (label.position) {
        props.position = label.position;
      }
      return React.createElement(IonLabel, props, this.localizeClosure(label.text));
    }
    return null;
  }

  generateInputs(): React.ReactNode[] {
    const nodes: React.ReactNode[] = [];
    Object.keys(this.schema).forEach((prop: string) => {
      const propInfo = this.schema[prop];
      if (propInfo.hidden) {
        return;
      }
      const input = this.getInputFromProps(prop, propInfo);
      if (input) {
        const item = React.createElement(
          IonItem,
          {
            ...(propInfo.type === SchemaType.BUTTON && { lines: 'none' }),
            ...(propInfo.wrapperClassName && { className: propInfo.wrapperClassName }),
          },
          this.generateLabel(this.schema[prop].label),
          input,
        );
        nodes.push(item);
      }
    });
    return nodes;
  }

  generateForm(): React.ReactNode {
    return React.createElement(IonList, { lines: 'full' }, ...this.generateInputs());
  }
}

export default BaseFormComponent;
