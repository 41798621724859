import React from 'react';

import { Log } from '@biteinc/common';
import type { LoginData, LoginResponse, SignupData, SignupResponse } from '@biteinc/core-react';
import { AppearanceHelper, Login, ModalService, Signup } from '@biteinc/core-react';
import { ApiResource, ApiVersion } from '@biteinc/enums';

import { MaitredClient, RequestMethod, Storage } from '../clients';
import { Store } from '../store';

class AuthService {
  public static showLogin(): Promise<LoginResponse> {
    return new Promise<LoginResponse>((resolve) => {
      const state = Store.getState();
      const { customerAuthProvider, signupUrl } = state.org;
      ModalService.showCustomModal({
        cssClass: 'login-modal',
        children: [
          React.createElement(Login, {
            lang: state.language,
            customStrings: state.appearance.customStrings,
            logoUrl: AppearanceHelper.getOrgImage(state.org, 'loyaltyLogo'),
            authProvider: customerAuthProvider,
            signupUrl,
            scope: MaitredClient.getScope(),
            close: () => {
              Log.debug('Close active modal');
              resolve({ success: false });
              ModalService.hideActiveModal();
            },
            showSignup: async () => {
              ModalService.hideActiveModal();
              const signupResult = await AuthService.showSignup();
              if (signupResult.success) {
                return AuthService.showLogin();
              }
              resolve({ success: false });
            },
            signin: async (payload: LoginData) => {
              const url = MaitredClient.generateUrl(ApiVersion.V2, ApiResource.Customer, 'login');
              const result = await MaitredClient.makeRequestWithLoadingSpinner({
                method: RequestMethod.POST,
                resource: url,
                body: payload,
              });
              if (result?.success) {
                Storage.setItem(MaitredClient.getTokenKey(), result.token);
                resolve({ success: true });
                ModalService.hideActiveModal();
              }
              return { error: result.message, success: false };
            },
          }),
        ],
      });
    });
  }

  public static showSignup(): Promise<SignupResponse> {
    return new Promise<SignupResponse>((resolve) => {
      const state = Store.getState();
      ModalService.showCustomModal({
        cssClass: 'signup-modal',
        children: [
          React.createElement(Signup, {
            lang: state.language,
            customStrings: state.appearance.customStrings,
            logoUrl: AppearanceHelper.getOrgImage(state.org, 'loyaltyLogo'),
            flashPrefix: state.flashResolvedHost,
            close: () => {
              ModalService.hideActiveModal();
              resolve({ success: false });
            },
            signup: async (payload: SignupData) => {
              const url = MaitredClient.generateUrl(ApiVersion.V2, ApiResource.Customer, 'signup');
              const result = await MaitredClient.makeRequestWithLoadingSpinner({
                method: RequestMethod.POST,
                resource: url,
                body: payload,
              });
              if (result?.success) {
                ModalService.hideActiveModal();
                return { success: true };
              }
              return { error: result.error, success: false };
            },
          }),
        ],
      });
    });
  }
}

export default AuthService;
