import { IonContent } from '@ionic/react';
import _ from 'lodash';
import React, { Component } from 'react';

import type { CustomerPaymentMethod } from '@biteinc/core-react';
import { ApiResource, ApiVersion, CardEntryMethod } from '@biteinc/enums';

import { MaitredClient, RequestMethod } from '../../../clients';
import { TextInput } from '../../../forms';

import './freedom-pay-card-form.scss';

const VALID_URLS = ['https://hpc.uat.freedompay.com', 'https://hpc.freedompay.com'];

interface FreedomPayCardFormProps {
  onSubmit(paymentMethod: CustomerPaymentMethod): void;
}

interface FreedomPayCardFormState {
  iframeHtml: string | null;
  nameOnCard: string | null;
}

class FreedomPayCardForm extends Component<FreedomPayCardFormProps, FreedomPayCardFormState> {
  private sessionKey!: string;

  private workflowId!: string;

  private hasEventListener = false;

  constructor(props: FreedomPayCardFormProps) {
    super(props);

    this.state = {
      iframeHtml: null,
      nameOnCard: null,
    };
  }

  private generateUrl(resource: string): string {
    return MaitredClient.generateUrl(ApiVersion.V2, ApiResource.Customer, resource);
  }

  private async submit(paymentMethod: CustomerPaymentMethod): Promise<void> {
    const url = this.generateUrl('payment-methods');
    const data = await MaitredClient.makeRequestWithLoadingSpinner({
      method: RequestMethod.POST,
      resource: url,
      body: {
        paymentMethod: {
          ...paymentMethod,
          cardEntryMethod: CardEntryMethod.ManuallyEntered,
          nameOnCard: this.state.nameOnCard,
          sessionKey: this.sessionKey,
          workflowId: this.workflowId,
        },
      },
    });
    if (data?.success) {
      this.props.onSubmit(data.paymentMethod);
    }
  }

  private async fetchIframe(): Promise<void> {
    const url = this.generateUrl('payment-methods/fp-iframe');
    const data = await MaitredClient.makeRequestWithLoadingSpinner({
      method: RequestMethod.GET,
      resource: url,
    });
    if (data?.success) {
      const { iframe, sessionKey, workflowId } = data;
      this.sessionKey = sessionKey;
      this.workflowId = workflowId;
      this.setState({ iframeHtml: iframe });
    }
  }

  getIframe():
    | {
        __html: string;
      }
    | undefined {
    if (this.state.iframeHtml) {
      return { __html: this.state.iframeHtml };
    }
    return undefined;
  }

  handleErrors(): void {}

  setNameOnCard(val: string): void {
    this.setState({ nameOnCard: val });
  }

  handleIframeMessage(e: any): void {
    // Ignore messages if not from FreedomPay
    if (!_.includes(VALID_URLS, e.origin)) {
      return;
    }
    const message = e.data;
    const data = message.data;
    switch (message.type) {
      case 1:
        this.handleErrors();
        break;
      case 3:
        void this.submit(data);
        break;
    }
  }

  listenForEvents(): void {
    if (!this.hasEventListener) {
      window.addEventListener('message', this.handleIframeMessage.bind(this));
      this.hasEventListener = true;
    }
  }

  async componentDidMount(): Promise<void> {
    await this.fetchIframe();
    this.listenForEvents();
  }

  render(): React.ReactNode {
    return React.createElement(
      IonContent,
      null,
      React.createElement(TextInput, {
        value: this.state.nameOnCard as string,
        label: 'Name on Card',
        placeholder: 'John Smith',
        change: this.setNameOnCard.bind(this),
      }),
      React.createElement('div', {
        dangerouslySetInnerHTML: this.getIframe(),
        className: 'ion-padding-horizontal iframe-container',
      }),
    );
  }
}

export default FreedomPayCardForm;
