export enum LoadingActions {
  LOADING = 'LOADING',
}

/**
 * Action Creators
 */

export function showLoading(show: boolean): { type: LoadingActions; show: boolean } {
  return { type: LoadingActions.LOADING, show };
}

/**
 * REDUCER
 * - Do not mutate object directly
 */
export function LoadingReducer(
  state: boolean = false,
  action: {
    type: LoadingActions;
    show: boolean;
  },
): boolean {
  switch (action.type) {
    case LoadingActions.LOADING:
      return action.show;
    default:
      return state;
  }
}
