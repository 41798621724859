import type { Action } from 'redux';

export enum MenuAction {
  ACTION,
}

/**
 * @todo
 * - State should be linked to a Menu model
 */
const initialState = {};

/**
 * REDUCER
 * - Do not mutate object directly
 */
export default (state: any = initialState, action: Action<any>): any => {
  switch (action.type) {
    case MenuAction.ACTION:
      return {
        ...state,
      };
  }
  return state;
};
