import React from 'react';

import type { Address } from '@biteinc/core-react';

export function addressAsMultipleLines(address: Address): React.ReactNode[] {
  const addressParts = [address.crossStreet, address.line1, address.line2].filter(
    (part: string) => !!part,
  );
  const cityStateZip = [address.city, address.state, address.postalCode].filter(
    (part: string) => !!part,
  );
  const nodes: React.ReactNode[] = addressParts.map((addressPart) => {
    return React.createElement('p', { className: 'ion-text-wrap' }, addressPart);
  });
  if (cityStateZip.length) {
    nodes.push(React.createElement('p', { className: 'ion-text-wrap' }, cityStateZip.join(' ')));
  }
  return nodes;
}
