import { IonToast } from '@ionic/react';
import React, { Component } from 'react';

import type { LocalizableString } from '@biteinc/common';
import { LocalizationHelper } from '@biteinc/core-react';

import { Store } from '../store';

export interface BaseState {
  showToast?: boolean;
}

export class BaseComponent<Props, State extends BaseState> extends Component<Props, State> {
  protected localize(localizableString: LocalizableString | string, params: string[] = []): string {
    const { language, appearance } = Store.getState();
    return LocalizationHelper.localize(
      localizableString,
      params,
      language,
      appearance.customStrings,
    );
  }

  protected localizeClosure(localizationClosure: LocalizationHelper.Closure): string {
    const { language, appearance } = Store.getState();
    return localizationClosure(language, appearance.customStrings);
  }

  showToast(isOpen: boolean, message?: string | null, color?: string | null): React.ReactNode {
    return React.createElement(IonToast, {
      isOpen,
      message: message || 'Success',
      duration: 6000,
      position: 'top',
      color: color || 'success',
      onDidDismiss: () => {
        this.setState({
          showToast: false,
        });
      },
    });
  }
}
