import React, { Component } from 'react';
import { connect } from 'react-redux';

import { AppearanceHelper } from '@biteinc/core-react';

import './checkin-code-display-page.scss';

class CheckinCodeDisplayPage extends Component<any, any> {
  private interval!: any;

  constructor(props: any) {
    super(props);
    this.state = {
      instructions: 'Enter the 4-digit code to collect your order.',
    };
  }

  componentDidMount(): void {
    this.interval = setInterval(
      () => {
        window.location.reload();
      },
      60 * 60 * 1000,
    );
  }

  componentWillUnmount(): void {
    clearInterval(this.interval);
  }

  render(): React.ReactNode {
    return React.createElement(
      'div',
      { className: 'checkin-code-display-page' },
      React.createElement('img', {
        className: 'logo',
        src: AppearanceHelper.getTitleImage(this.props.settings),
      }),
      React.createElement(
        'h1',
        { className: 'title' },
        this.props.location.checkinCodeScreenMessage,
      ),
      React.createElement('h3', { className: 'instructions' }, this.state.instructions),
      React.createElement('h2', { className: 'code' }, this.props.location.checkinCode),
    );
  }
}

const mapStateToProps = (
  state: any,
): {
  location: any;
  appearance: any;
  settings: any;
} => {
  return {
    location: state.location,
    appearance: state.menu.appearance,
    settings: state.menu.settings,
  };
};

export default connect(mapStateToProps)(CheckinCodeDisplayPage);
