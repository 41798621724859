import { IonButton, IonCol, IonItem, IonLabel, IonList, IonRow, IonToast } from '@ionic/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import type { Customer, CustomerOrder, OrderedItem } from '@biteinc/core-react';
import { StringHelper, TimeHelper } from '@biteinc/core-react';

import { MaitredClient, Storage } from '../../../../clients';
import { showLoading } from '../../../../reducers';
import { Store } from '../../../../store';

import './orders-list-component.scss';

import { ApiResource, ApiVersion } from '@biteinc/enums';

interface OrdersListProps {
  orders: CustomerOrder[];
  orgId: string;
  customer: Customer;
}

interface OrdersListState {
  showToast?: boolean;
  toastMessage?: string;
}

class OrdersList extends Component<OrdersListProps, OrdersListState> {
  constructor(props: OrdersListProps) {
    super(props);
    this.state = {};
  }

  private async emailOrderReceipt(order: CustomerOrder): Promise<void> {
    Store.dispatch(showLoading(true));
    const baseUrl = MaitredClient.generateUrl(ApiVersion.V2, ApiResource.Orders, order._id);
    const result = await MaitredClient.post(`${baseUrl}/receipt`, {
      email: this.props.customer.email,
    });
    Store.dispatch(showLoading(false));
    if (result.success) {
      this.setState({
        showToast: true,
        toastMessage: `Receipt successfully sent to ${this.props.customer.email}`,
      });
    }
  }

  private reorder(order: CustomerOrder): void {
    const orderId = order._id;
    Storage.setItem(`${order.location.urlSlug}:orderId`, orderId);
    window.location.href = order.location.url;
  }

  private showToast(): React.ReactNode {
    return React.createElement(IonToast, {
      isOpen: !!this.state.showToast,
      message: this.state.toastMessage || 'Success',
      duration: 6000,
      position: 'top',
      color: 'success',
      onDidDismiss: () => {
        this.setState({
          showToast: false,
        });
      },
    });
  }

  // Some items use a <br> in the name to break cals onto a new line
  private getOrderedItems(orderedItems: OrderedItem[]): React.ReactNode[] {
    return orderedItems.map((orderedItem) => {
      return React.createElement('span', {
        className: 'order-item',
        dangerouslySetInnerHTML: {
          __html: `<p>${orderedItem.priceOption.quantity}× ${orderedItem.name}</p>`,
        },
      });
    });
  }

  private getOrderItems(): React.ReactNode[] {
    if (!this.props.orders.length) {
      return [React.createElement('p', { className: 'ion-text-center' }, 'No orders yet.')];
    }

    return this.props.orders.map((order) => {
      const orderTime = TimeHelper.format(order.createdAt, 'MM/DD hh:mma');
      return React.createElement(
        IonItem,
        null,
        React.createElement(
          IonLabel,
          { className: 'order-summary' },
          React.createElement(
            IonRow,
            { className: 'location' },
            React.createElement(
              IonCol,
              null,
              React.createElement('h3', { className: 'location-name' }, order.location.name),
            ),
          ),
          React.createElement(
            IonRow,
            null,
            React.createElement(
              IonCol,
              null,
              React.createElement(
                'p',
                { className: 'order-price' },
                `$${StringHelper.dollarsFromCents(order.total)}`,
              ),
              this.getOrderedItems(order.orderedItems),
            ),
            React.createElement(
              IonCol,
              { className: 'ion-text-right' },
              React.createElement('p', { className: 'order-timestamp' }, orderTime),
            ),
          ),
          React.createElement(
            IonRow,
            { className: 'button-row' },
            React.createElement(
              IonCol,
              { className: 'button-col' },
              React.createElement(
                IonButton,
                {
                  className: 'receipt-button',
                  color: 'light',
                  onClick: () => {
                    void this.emailOrderReceipt(order);
                  },
                },
                'Email Receipt',
              ),
              React.createElement(
                IonButton,
                {
                  className: 'reorder-button',
                  color: 'primary',
                  onClick: () => {
                    this.reorder(order);
                  },
                },
                'Re-Order',
              ),
            ),
          ),
        ),
      );
    });
  }

  render(): React.ReactNode {
    return React.createElement(
      IonCol,
      null,
      React.createElement(IonList, { lines: 'full' }, this.getOrderItems()),
      this.showToast(),
    );
  }
}

const mapStateToProps = (
  state: any,
): {
  orgId: string;
  customer: Customer;
} => {
  return {
    orgId: state.org._id,
    customer: state.customer,
  };
};

export default connect(mapStateToProps)(OrdersList);
