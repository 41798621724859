import { connect } from 'react-redux';

import type { Header } from '@biteinc/core-react';

import { MaitredClient, Storage } from '../clients';

function mapStateToProps(
  state: any,
  otherProps: Omit<Header.Props, 'org' | 'host' | 'lang' | 'customStrings'> = {},
): Header.Props {
  return {
    lang: state.language,
    customStrings: state.appearance.customStrings,
    org: state.org,
    host: state.flashResolvedHost,
    lastLocationUrlSlug: Storage.getItem(`${MaitredClient.getScope()}:urlSlug`) ?? undefined,
    ...otherProps,
  };
}

export default connect(mapStateToProps);
