import { IonCol, IonImg, IonRow, IonThumbnail } from '@ionic/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { AppearanceHelper } from '@biteinc/core-react';

import './logo.scss';

import type { Org } from '../../types';

class Logo extends Component<any, any> {
  render(): React.ReactNode {
    return React.createElement(
      IonRow,
      null,
      React.createElement(
        IonCol,
        { className: 'ion-text-center' },
        React.createElement(
          IonThumbnail,
          { className: 'logo' },
          React.createElement(IonImg, {
            src: AppearanceHelper.getOrgImage(this.props.org, 'logo'),
          }),
        ),
      ),
    );
  }
}

const mapStateToProps = (
  state: any,
): {
  org: Org;
} => {
  return {
    org: state.org,
  };
};

export default connect(mapStateToProps)(Logo);
