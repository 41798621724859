import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import React from 'react';

import type { FormProps, Schema } from '@biteinc/core-react';
import { BaseFormComponent } from '@biteinc/core-react';

export interface GenericFormModalProps extends FormProps<any> {
  title: string;
  schema: Schema;
  submitButtonText: string;
  data?: any;
  onSubmit(data: any): void;
  onCancel(): void;
}

export class GenericFormModal extends BaseFormComponent<any, GenericFormModalProps, any> {
  constructor(props: GenericFormModalProps) {
    super(props);
    this.schema = props.schema;
    this.state = {
      data: props.data || {}, // Form
      showModal: true,
      errorMessage: null,
    };
  }

  private close(): void {
    this.setState({ showModal: false });
    this.props.onCancel();
  }

  onSubmit(): void {
    const { isValid, message } = this.isFormValid();
    if (isValid) {
      this.close();
      this.props.onSubmit(this.state.data);
    } else {
      this.setState({
        errorMessage: message,
      });
    }
  }

  render(): React.ReactNode {
    return React.createElement(
      IonPage,
      { className: 'generic-form-modal' },
      React.createElement(
        IonHeader,
        null,
        React.createElement(
          IonToolbar,
          null,
          React.createElement(
            IonButtons,
            { slot: 'start' },
            React.createElement(
              IonButton,
              { color: 'light', onClick: this.close.bind(this) },
              React.createElement(IonIcon, { slot: 'icon-only', icon: close }),
            ),
          ),
          React.createElement(IonTitle, null, this.props.title),
        ),
      ),
      React.createElement(
        IonContent,
        { className: 'ion-padding' },
        React.createElement(IonRow, null, React.createElement(IonCol, null, this.generateForm())),
      ),
      React.createElement(
        IonFooter,
        { className: 'ion-padding' },
        React.createElement(
          IonRow,
          null,
          React.createElement(
            IonCol,
            null,
            React.createElement(
              IonButton,
              {
                expand: 'block',
                color: 'primary',
                onClick: this.onSubmit.bind(this),
              },
              this.props.submitButtonText,
            ),
          ),
        ),
      ),
    );
  }
}
