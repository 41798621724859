import type { InputChangeEventDetail } from '@ionic/react';
import {
  IonButton,
  IonCol,
  IonContent,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
} from '@ionic/react';
import { navigateCircleOutline, personCircleOutline } from 'ionicons/icons';
import _ from 'lodash';
import qs from 'qs';
import React from 'react';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Strings } from '@biteinc/common';
import type { DeliveryAddress, SavedFulfillmentInfo } from '@biteinc/core-react';
import {
  AccountPopover,
  addressSchema,
  Footer,
  Header,
  LocalizationHelper,
  ModalService,
  TimeHelper,
} from '@biteinc/core-react';
import {
  ApiResource,
  ApiVersion,
  FulfillmentMethod,
  FulfillmentMethodHelper,
  isValidEnumValue,
} from '@biteinc/enums';

import { GoogleMapsClient, MaitredClient, RequestMethod, Storage } from '../../clients';
import type { BaseState } from '../../components';
import {
  BaseComponent,
  FulfillmentMethodPicker,
  LocationList,
  LocationsMap,
} from '../../components';
import { headerConnector } from '../../connectors';
import type { GenericFormModalProps } from '../../modals';
import { GenericFormModal } from '../../modals';
import { showLoading } from '../../reducers';
import { AuthService } from '../../services';
import { Store } from '../../store';
import type { LocationSearchResult, Org } from '../../types';

import '@ionic/react/css/ionic-swiper.css';
import 'swiper/swiper.scss';
import './location-search-page.scss';

interface LocationSearchPageProps extends RouteComponentProps<any> {
  org: Org;
  apiKey: string;
  host: string;
}

interface LocationSearchQuery {
  fulfillmentMethods: FulfillmentMethod[];
  page: number;
  limit: number;
  address?: string;
  lat?: number | null;
  long?: number | null;
  showDraftLocations?: boolean;
}

interface LocationSearchPageState extends BaseState {
  addressString?: string | null;
  addressPredictions: string[];
  isCurrentLocation: boolean;
  query: LocationSearchQuery;
  locations: LocationSearchResult[];
  deliveryAddress?: DeliveryAddress;
  disableAddressInput: boolean;
  hasLocationResult: boolean;
  accountPopover: {
    isVisible: boolean;
    position?: {
      top: number;
      left: number;
    } | null;
  };
  signedIn: boolean;
  errorMessage?: string | null;
  toastMessage?: string;
  toastColor?: string;
}

class LocationSearchPage extends BaseComponent<LocationSearchPageProps, LocationSearchPageState> {
  private geolocationAvailable = false;

  private declare mapsClient: GoogleMapsClient;

  private googleResultsRef: React.RefObject<any>;

  private predictionsRef: React.RefObject<any>;

  // Re-rendering each time something unrelated happens causes issues
  private locationsHaveChanged = false;

  constructor(props: LocationSearchPageProps) {
    super(props);

    // Kick off the script so it would load
    void this.getMapsClient();

    const query = this.parseQueryParams(this.props.location.search);
    this.state = {
      addressPredictions: [],
      isCurrentLocation: false,
      query,
      ...(query.address && { addressString: query.address }),
      locations: [],
      disableAddressInput: false,
      hasLocationResult: false,
      accountPopover: {
        isVisible: false,
      },
      signedIn: !!MaitredClient.getAuthToken(),
    };
    if ('geolocation' in navigator) {
      this.geolocationAvailable = true;
    }
    this.googleResultsRef = React.createRef();
    this.predictionsRef = React.createRef();
    this.handleClickOutsideToCloseAddressPredictions =
      this.handleClickOutsideToCloseAddressPredictions.bind(this);
  }

  private async getMapsClient(): Promise<GoogleMapsClient> {
    if (!this.mapsClient) {
      this.mapsClient = await GoogleMapsClient.init(this.props.apiKey);
    }
    return this.mapsClient;
  }

  private parseQueryParams(search: string): LocationSearchQuery {
    const query = qs.parse(search, { ignoreQueryPrefix: true });
    const fulfillmentMethods = (query.fulfillmentMethods as string[])
      ?.map((method) => {
        return parseInt(method, 10) as FulfillmentMethod;
      })
      ?.filter((method) => {
        return isValidEnumValue(FulfillmentMethod, method);
      });
    return {
      fulfillmentMethods: fulfillmentMethods?.length
        ? fulfillmentMethods
        : [this.props.org.fulfillmentMethods[0]],
      page: parseInt(query.page as string, 10) || 1,
      limit: parseInt(query.limit as string, 10) || 20,
      ...(query.address && { address: query.address as string }),
      ...(query.lat &&
        query.long && {
          lat: parseFloat(query.lat as string) || 1,
          long: parseFloat(query.long as string) || 20,
        }),
      ...(query.showDraftLocations === 'true' && { showDraftLocations: true }),
    };
  }

  private getHeaderButtons(): React.ReactNode[] {
    if (this.state.signedIn) {
      return [
        React.createElement(
          IonButton,
          {
            fill: 'clear',
            size: 'default',
            slot: 'end',
            color: 'primary',
            onClick: (event: React.SyntheticEvent<HTMLIonButtonElement, MouseEvent>) => {
              event.persist();
              const position = {
                top: event.currentTarget.offsetTop + event.currentTarget.offsetHeight,
                left: event.currentTarget.offsetLeft,
              };
              this.setState({
                accountPopover: {
                  isVisible: true,
                  position,
                },
              });
            },
          },
          this.localize(Strings.CUSTOMER_ACCOUNT_BUTTON_LOGGED_IN),
        ),
      ];
    }
    const headerButtons = [
      React.createElement(
        IonButton,
        {
          fill: 'clear',
          size: 'default',
          slot: 'end',
          color: 'primary',
          onClick: async () => {
            const { success } = await AuthService.showLogin();
            if (success) {
              this.setState({
                signedIn: true,
              });
            }
          },
        },
        React.createElement(IonIcon, { icon: personCircleOutline }),
        this.localize(Strings.LOG_IN_OR_JOIN),
      ),
    ];
    if (this.props.org.showSignupButton) {
      headerButtons.push(
        React.createElement(
          IonButton,
          {
            size: 'default',
            slot: 'end',
            color: 'primary',
            href: this.props.org.signupUrl || `/${MaitredClient.getScope()}/auth/signup`,
          },
          this.localize(Strings.SIGN_UP),
        ),
      );
    }
    return headerButtons;
  }

  private getHeader(): React.ReactNode {
    return React.createElement(headerConnector(Header), {
      buttons: this.getHeaderButtons(),
      logo: {
        slot: 'start',
      },
      color: 'clear',
      hideBackToMenu: true,
    });
  }

  private getGeoIcon(): React.ReactNode {
    if (this.geolocationAvailable) {
      return React.createElement(IonIcon, {
        slot: 'end',
        icon: navigateCircleOutline,
        onClick: this.getUserLocation.bind(this),
      });
    }
    return undefined;
  }

  private fulfillmentMethodChanged(fulfillmentMethod: FulfillmentMethod): void {
    const isDelivery = FulfillmentMethodHelper.isDelivery(fulfillmentMethod);
    this.setState({
      query: {
        ...this.state.query,
        fulfillmentMethods: [fulfillmentMethod],
      },
      disableAddressInput: isDelivery,
      locations: [],
      hasLocationResult: false,
    });
    if (isDelivery) {
      this.showDeliveryAddressModal();
    }
  }

  private handleClickOutsideToCloseAddressPredictions(event: Event): void {
    if (
      this.googleResultsRef &&
      !this.googleResultsRef.current?.contains(event.target) &&
      this.state.addressPredictions.length
    ) {
      this.setState({
        addressPredictions: [],
      });
    }
  }

  private showPredictions(): React.ReactNode {
    if (this.state.addressPredictions.length) {
      const listItems = this.state.addressPredictions.map((guess: string) => {
        return React.createElement(
          IonItem,
          {
            className: 'address-guess',
            onClick: () => {
              this.setState({
                query: {
                  ...this.state.query,
                  lat: null,
                  long: null,
                },
                addressString: guess,
                isCurrentLocation: false,
                addressPredictions: [],
              });
            },
          },
          React.createElement(IonLabel, null, guess),
        );
      });
      return React.createElement(
        IonList,
        {
          className: 'address-guess-list',
          ref: this.googleResultsRef,
        },
        listItems,
      );
    }
  }

  private async setAddress(event: CustomEvent<InputChangeEventDetail>): Promise<void> {
    const addressStr = event.detail.value;
    if (addressStr !== this.state.addressString && addressStr) {
      (await this.getMapsClient()).getAddressPredictions(addressStr, (predictions) => {
        if (this.state.addressString === addressStr) {
          this.setState({ addressPredictions: predictions });
        }
      });
    }
    this.setState({
      addressString: addressStr,
      isCurrentLocation: false,
    });
  }

  private getUserLocation(): void {
    Store.dispatch(showLoading(true));
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.setState({
          query: {
            ...this.state.query,
            lat: position.coords.latitude,
            long: position.coords.longitude,
          },
          addressString: this.localize(Strings.YOUR_CURRENT_LOCATION),
          isCurrentLocation: true,
        });
        Store.dispatch(showLoading(false));
        void this.searchLocations();
      },
      (err) => {
        Store.dispatch(showLoading(false));
        if (err.code === 3) {
          this.setState({
            showToast: true,
            toastMessage: this.localize(Strings.UNABLE_TO_FETCH_LOCATION_EXPLANATION),
            toastColor: 'primary',
          });
        }
      },
      { timeout: 10000, maximumAge: 60000 },
    );
  }

  private async searchLocations(): Promise<void> {
    const query = this.parseQueryParams(this.props.location.search);
    const params = {
      ..._.pickBy(this.state.query, _.identity),
      ...(this.state.addressString &&
        !this.state.isCurrentLocation && { address: this.state.addressString }),
      ...(query.showDraftLocations && { showDraftLocations: true }),
    };
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: `?${qs.stringify(params)}`,
    });
    const url = MaitredClient.generateUrl(ApiVersion.V2, ApiResource.Locations, 'search');
    const result = await MaitredClient.makeRequestWithLoadingSpinner({
      method: RequestMethod.GET,
      resource: url,
      params,
    });
    if (result.success) {
      this.locationsHaveChanged = true;
      this.setState({
        errorMessage: null,
        locations: result.locations,
        hasLocationResult: true,
      });
    } else {
      this.setState({
        errorMessage: result.message,
      });
    }
  }

  private getLocationListOrMobileHomeScreenImageCarousel(): React.ReactNode | undefined {
    if (this.state.hasLocationResult) {
      return React.createElement(LocationList, {
        locations: this.state.locations,
        onLocationSelect: (location) => {
          void this.navigateToLocation(location);
        },
      });
    }
    return this.getHomeScreenImageCarousel('mobileImage');
  }

  private showErrorMessage(): React.ReactNode | undefined {
    if (this.state.errorMessage) {
      return React.createElement(
        IonList,
        { lines: 'none', className: 'error-container' },
        React.createElement(
          IonItem,
          { className: 'error-info' },
          React.createElement(
            IonLabel,
            { className: 'ion-text-wrap' },
            React.createElement('h3', { className: 'ion-text-center' }, this.state.errorMessage),
          ),
        ),
      );
    }
    return undefined;
  }

  private async navigateToLocation(location: LocationSearchResult): Promise<void> {
    const fulfillmentInfo: SavedFulfillmentInfo = {
      fulfillmentMethod: this.state.query.fulfillmentMethods[0],
      ...(this.state.deliveryAddress && { deliveryAddress: this.state.deliveryAddress }),
      validUntil: Date.now() + TimeHelper.DAY,
      needsToSelectOrderTime: true,
    };
    if (FulfillmentMethodHelper.isDelivery(fulfillmentInfo.fulfillmentMethod)) {
      const url = MaitredClient.generateUrl(
        ApiVersion.V2,
        ApiResource.Locations,
        `${location._id}/validate-delivery-address`,
      );
      const result = await MaitredClient.post(
        url,
        {
          address: this.state.deliveryAddress,
          fulfillmentMethod: fulfillmentInfo.fulfillmentMethod,
        },
        true,
      );
      if (!result.success) {
        this.setState({
          showToast: true,
          toastColor: 'danger',
          toastMessage: result.message,
        });
        return;
      }
    }
    Storage.setJsonItem('fulfillmentInfo', fulfillmentInfo);
    window.location.href = location.url;
  }

  private getHomeScreenImageCarousel(
    imageField: 'mobileImage' | 'desktopImage',
  ): React.ReactNode | undefined {
    const homeScreenImages = this.props.org.homeScreenImages?.filter((homeScreenImage) => {
      return homeScreenImage[imageField]?.length;
    });
    if (!homeScreenImages?.length) {
      return;
    }

    return React.createElement(
      Swiper,
      {
        className: 'home-screen-image-carousel',
        preventInteractionOnTransition: true,
        slidesPerView: 1,
        centeredSlides: true,
        loop: true,
        speed: 500,

        modules: [Autoplay],
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
      },
      homeScreenImages.map((homeScreenImage, i) => {
        return React.createElement(
          SwiperSlide,
          null,
          React.createElement(IonImg, {
            className: 'home-screen-image',
            src: homeScreenImage?.[imageField]?.[0].url,
            alt: `Promo Image #${i + 1}`,
          }),
        );
      }),
    );
  }

  private getLocationMapOrDesktopHomeScreenImageCarousel(): React.ReactNode {
    if (this.state.locations.length) {
      const updateMarkers = this.locationsHaveChanged;
      this.locationsHaveChanged = false;
      return React.createElement(LocationsMap, {
        locations: this.state.locations,
        updateMarkers,
        selectedLocation: (location) => {
          void this.navigateToLocation(location);
        },
      });
    }

    return this.getHomeScreenImageCarousel('desktopImage');
  }

  private showDeliveryAddressModal(): void {
    const deliveryAddress = Storage.getJsonItem('deliveryAddress');

    ModalService.showCustomModal({
      cssClass: 'delivery-form-modal',
      children: [
        React.createElement(GenericFormModal, {
          title: this.localize(Strings.DELIVERY_ADDRESS),
          schema: addressSchema,
          submitButtonText: this.localize(Strings.CONFIRM_ADDRESS),
          ...((this.state.deliveryAddress || deliveryAddress) && {
            data: this.state.deliveryAddress || deliveryAddress,
          }),
          onSubmit: (address: DeliveryAddress) => {
            const addressParts = [address.line1, address.line2, address.city, address.postalCode];
            Storage.setJsonItem('deliveryAddress', address);
            const addressString = addressParts
              .filter((val) => {
                return val;
              })
              .join(', ');
            this.setState(
              {
                query: {
                  ...this.state.query,
                  lat: null,
                  long: null,
                },
                addressString,
                deliveryAddress: address,
              },
              () => {
                void this.searchLocations();
              },
            );
            ModalService.hideActiveModal();
          },
          onCancel: () => {
            ModalService.hideActiveModal();
          },
        } as GenericFormModalProps),
      ],
    });
  }

  /**
   * Custom implementation for now due to some bugs in Ionic v6.0.0-rc0
   */
  private showAccountPopover(): React.ReactNode {
    if (!this.state.accountPopover.isVisible) {
      return;
    }
    const state = Store.getState();
    const { position, isVisible } = this.state.accountPopover;
    return React.createElement(
      'div',
      {
        className: 'popover-container',
        style: position,
      },
      React.createElement(AccountPopover, {
        lang: state.language,
        customStrings: state.appearance.customStrings,
        isVisible,
        onDismiss: () => {
          this.setState({
            accountPopover: {
              isVisible: false,
              position: null,
            },
          });
        },
        onSignOut: () => {
          // Best effort log out call
          void MaitredClient.post('/api/v2/customer/log-out', null);
          Storage.removeItem(`${MaitredClient.getScope()}:token`);
          window.location.reload();
        },
        customerScope: MaitredClient.getScope(),
        onShowQrCode: null,
      }),
    );
  }

  componentDidMount(): void {
    document.addEventListener('mousedown', this.handleClickOutsideToCloseAddressPredictions);
    // When we land on this page and we have an address - then immediately search
    if (this.state.addressString) {
      void this.searchLocations();
    }
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleClickOutsideToCloseAddressPredictions);
  }

  render(): React.ReactNode {
    return React.createElement(
      IonPage,
      null,
      this.getHeader(),
      this.showAccountPopover(),
      React.createElement(
        IonContent,
        null,
        React.createElement(
          IonRow,
          {
            className: 'search-container ion-no-padding',
          },
          React.createElement(
            IonCol,
            {
              className: 'location-search-col',
              sizeXs: '12',
              sizeSm: '12',
              sizeMd: '6',
              sizeLg: '5',
              sizeXl: '5',
            },
            React.createElement(
              'div',
              { className: 'search-inputs' },
              React.createElement(
                'h1',
                null,
                this.localize(Strings.LOCATION_SEARCH_HEADER, [this.props.org.name]),
              ),
              React.createElement(
                'p',
                { className: 'ion-no-margin' },
                this.localize(Strings.LOCATION_SEARCH_FULFILLMENT_QUESTION),
              ),
              React.createElement(FulfillmentMethodPicker, {
                selectedMethod: this.state.query.fulfillmentMethods[0],
                fulfillmentMethods: this.props.org.fulfillmentMethods,
                methodPicked: this.fulfillmentMethodChanged.bind(this),
              }),
              React.createElement(
                'p',
                null,
                this.localize(Strings.LOCATION_SEARCH_FULFILLMENT_PROMPT, [
                  this.localize(
                    LocalizationHelper.localizeEnum.FulfillmentMethod(
                      this.state.query.fulfillmentMethods[0],
                    ),
                  ),
                ]),
              ),
              React.createElement(
                'form',
                {
                  onSubmit: (event: Event) => {
                    event.preventDefault();
                    void this.searchLocations();
                  },
                },
                React.createElement(
                  IonList,
                  {
                    className: 'ion-margin-horizontal search-input-list',
                    lines: 'none',
                  },
                  React.createElement(
                    IonItem,
                    null,
                    React.createElement(IonLabel, null, 'Near'),
                    React.createElement(IonInput, {
                      type: 'text',
                      disabled: this.state.disableAddressInput,
                      placeholder: this.localize(Strings.LOCATION_SEARCH_INPUT_PLACEHOLDER),
                      value: this.state.addressString,
                      onIonChange: (event: CustomEvent<InputChangeEventDetail>) => {
                        void this.setAddress(event);
                      },
                      ref: this.predictionsRef,
                      onFocus: () => {
                        const safeAreaHeight =
                          parseInt(
                            getComputedStyle(document.documentElement).getPropertyValue(
                              '--bite-safe-area-top',
                            ),
                            10,
                          ) || 0;
                        window.scrollTo(
                          0,
                          this.predictionsRef.current.offsetTop + 200 - safeAreaHeight,
                        );
                      },
                      onClick: () => {
                        if (this.state.disableAddressInput) {
                          this.showDeliveryAddressModal();
                        }
                      },
                    }),
                    this.getGeoIcon(),
                  ),
                ),
                this.showPredictions(),
                React.createElement(
                  IonRow,
                  { className: 'ion-margin-top' },
                  React.createElement(
                    IonCol,
                    { size: '8', offset: '2' },
                    React.createElement(
                      IonButton,
                      {
                        type: 'submit',
                        expand: 'block',
                        disabled: !this.state.addressString,
                      },
                      this.localize(Strings.LOCATION_SEARCH_BUTTON),
                    ),
                  ),
                ),
              ),
            ),
            this.showErrorMessage(),
            this.getLocationListOrMobileHomeScreenImageCarousel(),
          ),
          React.createElement(
            IonCol,
            {
              className: 'map-col ion-no-padding ion-hide-xs ion-hide-sm',
              sizeMd: '6',
              sizeLg: '7',
              sizeXl: '7',
            },
            this.getLocationMapOrDesktopHomeScreenImageCarousel(),
          ),
        ),
        React.createElement(Footer, {
          org: this.props.org,
          flashPrefix: '',
        }),
        this.showToast(!!this.state.showToast, this.state.toastMessage, this.state.toastColor),
      ),
    );
  }
}

const mapStateToProps = (
  state: any,
): {
  org: Org;
  apiKey: string;
  host: string;
} => {
  return {
    org: state.org,
    apiKey: state.googleApiKey,
    host: state.flashResolvedHost,
  };
};

export default connect(mapStateToProps)(LocationSearchPage);
