import { IonButton, IonCol, IonContent, IonPage, IonRow } from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';

import { Header, PasswordResetRequestSchema } from '@biteinc/core-react';
import type { CustomerAuthProvider } from '@biteinc/enums';
import { ApiResource, ApiVersion } from '@biteinc/enums';

import { headerConnector } from '../../connectors';
import { BaseFormComponent } from '../../forms';
import type { FormProps } from '../../forms/base-form-component';
import ResponsiveContent from '../../layout/responsive-content/responsive-content';

interface PasswordResetProps extends FormProps {
  authProvider?: CustomerAuthProvider;
}

class PasswordResetPage extends BaseFormComponent<PasswordResetProps, any> {
  resource: ApiResource = ApiResource.Customer;

  version: ApiVersion = ApiVersion.V2;

  path = 'reset-password';

  schema = PasswordResetRequestSchema;

  private declare processing: boolean;

  constructor(props: any) {
    super(props);
    this.state = {
      showToast: false,
      toastMessage: null,
      toastColor: null,
      data: {
        email: null,
      },
    };
  }

  async submit(): Promise<void> {
    if (this.processing) {
      return;
    }
    this.processing = true;
    const result = await super.submit();
    if (result?.success) {
      this.setState({
        showToast: true,
        toastMessage: result.message,
        toastColor: 'success',
      });
    } else {
      this.setState({
        showToast: true,
        toastColor: 'danger',
        toastMessage: result.message,
      });
    }
    this.processing = false;
  }

  render(): React.ReactNode {
    return React.createElement(
      IonPage,
      null,
      React.createElement(headerConnector(Header)),
      React.createElement(
        IonContent,
        { className: 'ion-padding password-reset-request-container' },
        React.createElement(
          ResponsiveContent,
          null,
          React.createElement(
            IonRow,
            null,
            React.createElement(IonCol, { size: '10', offset: '1' }, this.generateForm()),
          ),
          React.createElement(
            IonRow,
            null,
            React.createElement(
              IonCol,
              { size: '10', offset: '1' },
              React.createElement(
                IonButton,
                {
                  expand: 'block',
                  className: 'request-reset-button',
                  onClick: this.submit.bind(this),
                },
                'Reset Password',
              ),
            ),
          ),
        ),
        this.showToast(this.state.showToast, this.state.toastMessage, this.state.toastColor),
      ),
    );
  }
}

const mapStateToProps = (
  state: any,
): {
  authProvider: CustomerAuthProvider;
} => {
  return {
    authProvider: state.org.customerAuthProvider,
  };
};

export default connect(mapStateToProps)(PasswordResetPage);
